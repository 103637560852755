/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type HouseholdAndPerson = {
    household: {
        id: number;
        name: string;
        createdAt: string;
    };
    householdPerson: {
        id: number;
        householdId: number;
        personId: number;
        person: {
            id: number;
            firstName: string;
            lastName: string;
            gender: HouseholdAndPerson.gender | null;
            birthdate?: string;
            email?: {
                id: number;
                emailAddress: string;
            };
            phone?: {
                id: number;
                phoneNumber: string;
            };
            uuid: string;
            userId?: number | null;
            age?: number;
        };
        isOrganizer: boolean;
    };
};

export namespace HouseholdAndPerson {

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }


}

