import React from 'react';
import { useFormSelector } from 'state/form/formSlice';
import { Overview } from 'components/Form';

export const OverviewPage = () => {
  const form = useFormSelector();

  if (!form) {
    return null;
  }

  const { name, participantGroups, organizationName, season, id, openedAt, closedAt, status } = form;

  return (
    <Overview
      registration={{
        id,
        name,
        participantGroups: participantGroups,
        startDate: openedAt,
        endDate: closedAt,
        season,
        status,
      }}
      orgName={organizationName}
      orgId={form.organizationId} // TODO: Remove this once we remove waitlist feature flag.
    />
  );
};
