/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Person = {
    id: number;
    firstName: string;
    lastName: string;
    gender: Person.gender | null;
    birthdate?: string;
    email?: {
        id: number;
        emailAddress: string;
    };
    phone?: {
        id: number;
        phoneNumber: string;
    };
    uuid: string;
    userId?: number | null;
    age?: number;
};

export namespace Person {

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }


}

