export function splitAdjustmentValues(values: { [key: string]: string | string[] }) {
  const adjustmentValues = {} as { [key: string]: string | string[] };
  const fieldValues = {} as { [key: string]: string | string[] };

  for (const [key, value] of Object.entries(values)) {
    if (key.includes('-')) {
      adjustmentValues[key] = value;
    } else {
      fieldValues[key] = value;
    }
  }

  return [fieldValues, adjustmentValues];
}
