import { createSelector } from '@reduxjs/toolkit';
import { useAppSelector } from '../hooks';
import {
  formCapacityDetailsSelector,
  formGroupsWaitlistAllowedSelector,
  inactiveFormGroupsSelector,
} from '../form/formSlice';
import { inCartRegistrationsSelector } from './registrationSlice';
import { Registration } from '../../core/api';

export const cartHasGroupsWithCapacityLimitSelector = createSelector(
  formCapacityDetailsSelector,
  (capacityDetails) =>
    capacityDetails && capacityDetails.length > 0 && capacityDetails.some((group) => group.remainingCapacity !== null)
);

export const useCartHasGroupsWithCapacityLimitSelector = () => useAppSelector(cartHasGroupsWithCapacityLimitSelector);

export const registrationsAtCapacitySelector = createSelector(
  formGroupsWaitlistAllowedSelector,
  formCapacityDetailsSelector,
  inCartRegistrationsSelector,
  (groupsWithWaitlist, participantGroupCapacity, inCartRegistrations) => {
    const participantGroupCounts: { [key: number]: number } = {};

    new Set(inCartRegistrations.map((r) => r.participantGroupId)).forEach((groupId) => {
      participantGroupCounts[groupId] = inCartRegistrations.filter(
        (r) => r.participantGroupId === groupId && !r.waitlistStatus
      ).length;
    });

    const isAtCapacity = (reg: Registration) => {
      const capacityDetail = participantGroupCapacity?.find((g) => g.participantGroupId === reg.participantGroupId);

      if (!capacityDetail || capacityDetail.remainingCapacity === null) return false;

      return capacityDetail.remainingCapacity < participantGroupCounts[reg.participantGroupId];
    };

    const hasWaitlist = (reg: Registration) => !!groupsWithWaitlist.find((g) => g.id === reg.participantGroupId);

    const atCapacityWithoutWaitlist = inCartRegistrations.filter((reg) => isAtCapacity(reg) && !hasWaitlist(reg));

    const atCapacityWithWaitlistAndModalInfoNeeded = inCartRegistrations.filter(
      (reg) => isAtCapacity(reg) && hasWaitlist(reg) && !reg.waitlistStatus
    );

    return {
      atCapacityWithoutWaitlist,
      atCapacityWithWaitlistAndModalInfoNeeded,
    };
  }
);

export const useRegistrationsAtCapacitySelector = () => useAppSelector(registrationsAtCapacitySelector);

export const registrationsAllWaitlistedSelector = createSelector(
  inCartRegistrationsSelector,
  (inCartRegistrations) =>
    inCartRegistrations.length > 0 &&
    inCartRegistrations.every((reg) => reg.waitlistStatus === Registration.waitlistStatus.WAITLISTED)
);

export const useRegistrationsAllWaitlistedSelector = () => useAppSelector(registrationsAllWaitlistedSelector);

export const registrationsFromInactiveParticipantGroupsSelector = createSelector(
  inCartRegistrationsSelector,
  inactiveFormGroupsSelector,
  (inCartRegistrations, inactiveGroups) => {
    const inactiveGroupIds = inactiveGroups.map((g) => g.id);
    return inCartRegistrations.filter((r) => inactiveGroupIds.includes(r.participantGroupId));
  }
);

export const useRegistrationsFromInactiveParticipantGroupsSelector = () =>
  useAppSelector(registrationsFromInactiveParticipantGroupsSelector);
