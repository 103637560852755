import * as React from 'react';

import { RegistrationWithDiscounts } from 'core/api';
import { loadDiscountSearchResults } from 'state/form/formSlice';
import {
  loadRegistrationsWithDiscounts,
  useRegistrationsWithDiscountsSelector,
} from 'state/registration/registrationSlice';
import { useAppDispatch } from 'state/hooks';
import { discountsFromLocalStorage, saveDiscountsToLocalStorage } from './DiscountStorage';

type ReturnType = [
  coupons: string[],
  setCouponCodes: (value: string[]) => void,
  errors: string[],
  removeCoupon: (couponCode: string) => void,
  discountsAmount: number,
  invalidDiscountCodes: string[]
];

export const useDiscounts = (formId: number, formResultIds: number[]): ReturnType => {
  const { useEffect, useState } = React;
  const [discountCodes, setDiscountCodes] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [discountsAmount, setDiscountsAmount] = useState<number>(0);
  const [invalidDiscountCodes, setInvalidDiscountCodes] = useState<string[]>([]);

  const registrationsWithDiscounts: RegistrationWithDiscounts =
    useRegistrationsWithDiscountsSelector() as RegistrationWithDiscounts;

  const dispatch = useAppDispatch();

  const getDiscount = async (discountCode: string) => {
    const discountAdjustment = await dispatch(loadDiscountSearchResults({ formId, couponCode: discountCode })).unwrap();

    if (discountAdjustment.data) {
      const { couponCode } = discountAdjustment.data;
      if (!couponCode) return;

      let newDiscounts: string[] = [];

      // If we have discounts update them otherwise, create new
      const appliedDiscounts = discountsFromLocalStorage(formId, formResultIds);
      if (appliedDiscounts) {
        newDiscounts = Array.from(new Set([...appliedDiscounts, couponCode]));
        saveDiscountsToLocalStorage(formId, newDiscounts, formResultIds);
      } else {
        newDiscounts.push(couponCode);
        saveDiscountsToLocalStorage(formId, newDiscounts, formResultIds);
      }

      dispatch(loadRegistrationsWithDiscounts({ formId: +formId, couponCodes: newDiscounts.join(',') }));
    }
  };

  const isDiscountValid = async (discountCode: string) => {
    const discountAdjustment = await dispatch(loadDiscountSearchResults({ formId, couponCode: discountCode })).unwrap();

    if (discountAdjustment.data) {
      getDiscount(discountCode);
    } else {
      setErrors(['Code is invalid.', ...errors]);
    }
  };

  const removeDiscountCode = (couponCode: string) => {
    const remianingCodes = discountCodes.filter((discountCode) => discountCode !== couponCode);
    setDiscountCodes(remianingCodes);
    // If we have discounts update them otherwise, create new
    const appliedDiscounts = discountsFromLocalStorage(formId, formResultIds);
    if (appliedDiscounts) {
      const newDiscounts = appliedDiscounts.filter((discount: string) => discount !== couponCode);
      saveDiscountsToLocalStorage(formId, newDiscounts, formResultIds);

      dispatch(loadRegistrationsWithDiscounts({ formId: +formId, couponCodes: newDiscounts.join(',') }));
    }
  };

  const handleDiscountCodes = (discountCodes: string[]) => {
    setErrors(['']);

    setDiscountCodes(discountCodes);
    const lastItem = discountCodes[discountCodes.length - 1];

    if (!lastItem) {
      setErrors(['Code is invalid.', ...errors]);
    } else {
      const appliedDiscounts = discountsFromLocalStorage(formId, formResultIds);

      if (appliedDiscounts.includes(lastItem)) {
        setErrors(['Code has already been applied.', ...errors]);
      } else {
        isDiscountValid(lastItem);
      }
    }
  };

  // When discount codes changes (i.e. add, remove)
  // This is called when we use 'setDiscounts' in Cart.tsx
  useEffect(() => {
    if (discountCodes.length > 0) {
      const lastItem = discountCodes[discountCodes.length - 1];

      getDiscount(lastItem);
    }
  }, [discountCodes]);

  // This is called the backend returns a list of discounts
  useEffect(() => {
    // Calculate discounts amount when a discount is added
    if (registrationsWithDiscounts.discounts) {
      const { discounts } = registrationsWithDiscounts;

      const savedDiscountCodes: string[] = [];
      const discountsAmount = discounts.reduce((accumulator, curr) => {
        savedDiscountCodes.push(curr.name);
        return (accumulator = accumulator + Math.abs(curr.amount));
      }, 0);

      setDiscountsAmount(discountsAmount);

      // Check if we have invalid coupons since last page refresh. if a discount code in localStorage
      // does not have a matching entry in the discounts from the back-end, then mark it as invalid
      const appliedDiscounts = discountsFromLocalStorage(formId, formResultIds);

      // This finds codes in discount codes that are not in stored discount codes
      const invalidDiscounts = appliedDiscounts.filter((x) => !savedDiscountCodes.includes(x)).filter((x) => x);

      setInvalidDiscountCodes(invalidDiscounts);
    }
  }, [registrationsWithDiscounts]);

  return [discountCodes, handleDiscountCodes, errors, removeDiscountCode, discountsAmount, invalidDiscountCodes];
};
