/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FormFieldVisibilityRule = {
    id: number;
    formFieldId: number;
    order: number;
    chainingComparison: FormFieldVisibilityRule.chainingComparison;
    targetFormFieldId: number;
    comparison: FormFieldVisibilityRule.comparison;
    answerToMatch: string;
};

export namespace FormFieldVisibilityRule {

    export enum chainingComparison {
        OR = 'or',
        AND = 'and',
    }

    export enum comparison {
        EQ = 'eq',
        GT = 'gt',
        LT = 'lt',
        LTE = 'lte',
        GTE = 'gte',
        NEQ = 'neq',
        CONTAINS = 'contains',
        NOT_CONTAINS = 'not_contains',
    }


}

