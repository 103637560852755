import { Form, Formik } from 'formik';
import { useFormSelector } from 'state/form/formSlice';
import { loginUser, useLoginEmailSelctor } from 'state/user/userSlice';
import * as Yup from 'yup';
import logoImage from '../../assets/images/logo.svg';

import { ActionContainer } from '../../components/shared';
import { FormField, FormFieldType } from 'components/Form';
import * as validations from '../../core/validations';
import { useAppDispatch, useAppNavigate } from '../../state/hooks';

const schema = Yup.object({
  password: validations.LOGIN_PASSWORD,
});

export const Login = () => {
  const dispatch = useAppDispatch();
  const form = useFormSelector();
  const email = useLoginEmailSelctor() || '';
  const navigate = useAppNavigate();

  return (
    <div className="EmailEntry">
      <Formik
        initialValues={{
          password: '',
        }}
        validationSchema={schema}
        onSubmit={async (values, { setFieldError, setSubmitting }) => {
          const { data } = await dispatch(loginUser({ email, password: values.password })).unwrap();

          if (!data?.success) {
            setSubmitting(false);
            setFieldError('password', 'Sorry, we didn’t recognize that password.');
          } else {
            navigate(`/form/${form?.id}`, { replace: true });
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ActionContainer
            action={handleSubmit}
            actionLabel="Log In"
            title={form?.organizationName || 'Registration'}
            submitting={isSubmitting}
            displayBackButton={true}
          >
            <Form>
              <div className="sui-flex sui-justify-center sui-mb-3">
                <img className="logo-image" src={logoImage} alt="TeamSnap" />
              </div>

              <h1 className="sui-heading-md">Welcome back!</h1>

              <p className="sui-body sui-text-gray-8 sui-mb-3">Enter your TeamSnap password.</p>

              <FormField type={FormFieldType.PASSWORD_INPUT} label="Password" name="password" placeholder="" />

              <span
                className="sui-body sui-mb-3 sui-border-0 sui-text-blue"
                style={{ background: 'none', cursor: 'pointer' }}
                onClick={() => {
                  navigate(`/form/${form?.id}/reset`);
                }}
                onKeyUp={(event) => {
                  event.key === 'Enter' && navigate(`/form/${form?.id}/reset`);
                }}
                tabIndex={0}
                role="button"
              >
                Forgot password?
              </span>
            </Form>
          </ActionContainer>
        )}
      </Formik>
    </div>
  );
};
