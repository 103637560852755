import { Button, Loader } from '@teamsnap/teamsnap-ui';

// Local Imports
import './ConfirmationModal.scss';

type Redirect = {
  href: string;
  text: string;
};

interface ConfirmationModalProps {
  confirmIcon?: string;
  confirmationText?: string;
  dismissIcon?: string;
  dismissText?: string;
  handleClose: () => void;
  handleConfirm?: () => void;
  helperText?: string;
  isOpen: boolean;
  text: string;
  title?: string;
  redirect?: Redirect;
  isSubmitting?: boolean;
  testId?: string;
}

export const ConfirmationModal = ({
  confirmIcon,
  confirmationText,
  dismissIcon,
  dismissText,
  handleClose,
  handleConfirm,
  helperText,
  isOpen,
  text,
  title,
  redirect,
  isSubmitting,
  testId = 'confirmation-modal',
}: ConfirmationModalProps) => {
  return (
    <>
      {isOpen && (
        <>
          <div className="ConfirmationModal-overlay"></div>

          <div className="ConfirmationModal sui-flex sui-flex-col sui-p-2" data-testid={testId}>
            {title && <h3 className="sui-heading-md sui-p-2">{title}</h3>}

            <div className="scrollable-content-container">
              <div>
                {title ? <p>{text}</p> : <h3>{text}</h3>}
                {helperText && <p className="u-padTopSm">{helperText}</p>}
              </div>
            </div>

            <div className="sui-flex sui-items-center sui-justify-end sui-mt-4">
              <Button
                icon={dismissIcon ?? ''}
                iconPosition="right"
                key="add-another-member"
                mods="sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
                onClick={handleClose}
                color={`${!redirect && !handleConfirm ? 'primary' : ''}`}
                isDisabled={isSubmitting}
              >
                {dismissText ?? 'Cancel'}
              </Button>

              {!redirect && handleConfirm && (
                <Button
                  icon={confirmIcon ?? ''}
                  iconPosition="right"
                  color="primary"
                  mods="sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1 sui-ml-1"
                  onClick={handleConfirm}
                  isDisabled={isSubmitting}
                  testId={`${testId}-action`}
                >
                  {isSubmitting ? <Loader type="spin" /> : confirmationText ?? 'Confirm'}
                </Button>
              )}

              {redirect && (
                <a
                  className="Button Button--primary u-textDecorationNone sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1 sui-ml-1"
                  href={redirect.href}
                >
                  <span>
                    <span>{redirect.text}</span>
                  </span>
                </a>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
