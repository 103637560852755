import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { ActionContainer } from 'components/shared';
import { FormField, FormFieldType } from 'components/Form';
import * as validations from 'core/validations';
import { useAppDispatch, useAppNavigate } from 'state/hooks';
import { updateLogin, useLoginEmailSelctor, userExists } from 'state/user/userSlice';
import { useFormSelector } from 'state/form/formSlice';

import logoImage from '../../assets/images/logo.svg';

const schema = Yup.object({
  email: validations.EMAIL,
});

export const Email = () => {
  const dispatch = useAppDispatch();
  const form = useFormSelector();
  const navigate = useAppNavigate();
  const email = useLoginEmailSelctor();

  return (
    <div className="EmailEntry">
      <Formik
        initialValues={{
          email: email || '',
        }}
        validationSchema={schema}
        onSubmit={async (values, { setFieldError, setSubmitting }) => {
          const result = await dispatch(userExists(values.email)).unwrap();

          if (result.error) {
            setSubmitting(false);
            setFieldError(
              'email',
              'Oh no! It looks like something went wrong. Please try again or contact TeamSnap Support for help.'
            );
          } else {
            dispatch(updateLogin({ loginEmail: values.email }));
            navigate(`/form/${form?.id}/${result.data?.exists ? 'login' : 'newUser'}`);
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ActionContainer
            action={handleSubmit}
            actionLabel="Next"
            title={form?.organizationName || 'Registration'}
            submitting={isSubmitting}
          >
            <Form>
              <div className="sui-flex sui-justify-center sui-mb-3">
                <img className="logo-image" src={logoImage} alt="TeamSnap" />
              </div>

              <h1 className="sui-heading-md">{form?.name || form?.program.name || form?.organizationName}</h1>

              <p className="sui-body sui-text-gray-8 sui-mb-3">
                Sign in or create your TeamSnap account to access this registration.
              </p>

              <FormField testId="user-login-input" type={FormFieldType.EMAIL} label="Email" name="email" placeholder="email@email.com" />
            </Form>
          </ActionContainer>
        )}
      </Formik>
    </div>
  );
};
