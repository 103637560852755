import { GroupData } from 'pages/Form/GroupsPage';

export type Registrant = {
  firstName: string;
  lastName: string;
};

interface Props {
  registrant: Registrant;
  group?: GroupData;
}

export const RegistrantInfo = ({ registrant, group }: Props) => {
  return (
    <div className="sui-w-full sui-bg-gray-1">
      <div className="sui-w-full sui-flex sui-py-1 sui-px-2 sui-mx-auto sui-max-w-screen-t sui-text-gray-8 sui-body">
        <div data-testid="RegistrantInfo--participant-name">
          Registering: {registrant?.firstName} {registrant?.lastName}
        </div>
        {group && <div data-testid="RegistrantInfo--group-name" className="sui-ml-2">{group.name}</div>}
      </div>
    </div>
  );
};
