// Local imports
import { FormAdjustment } from 'core/api';
import { AdjustmentQuantity } from './AdjustmentQuantity';
import { AdjustmentAmount } from './AdjustmentAmount';

interface Props {
  adjustment: FormAdjustment;
}

export const AdjustmentValue = ({ adjustment }: Props) => {
  const needsQuantity = adjustment.maxQuantity > 1;
  const needsAmount = adjustment.maxAmount > 0;

  if (!adjustment || (!needsQuantity && !needsAmount)) {
    return null;
  }

  return needsQuantity ? <AdjustmentQuantity adjustment={adjustment} /> : <AdjustmentAmount adjustment={adjustment} />;
};
