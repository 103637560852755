/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RegistrationWaiver = {
    id: number;
    registrationId: number;
    formWaiverId: number;
    signature: string;
    signatureType: RegistrationWaiver.signatureType;
    userIpAddress: string;
};

export namespace RegistrationWaiver {

    export enum signatureType {
        CHECKBOX_ACCEPT = 'checkbox_accept',
        INITIALS = 'initials',
        FULL_NAME = 'full_name',
        E_SIGNATURE = 'e_signature',
    }


}

