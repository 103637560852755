/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FormAdjustment } from '../models/FormAdjustment';
import type { FormMessage } from '../models/FormMessage';
import type { FormWaiverUrl } from '../models/FormWaiverUrl';
import type { ParticipantGroupCount } from '../models/ParticipantGroupCount';
import type { RegistrationForm } from '../models/RegistrationForm';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FormsService {

    /**
     * Returns Form Waiver Download Url
     * @param id
     * @param waiverId
     * @returns FormWaiverUrl Success
     * @throws ApiError
     */
    public static geFormWaiverDownloadUrl(
        id: number,
        waiverId: number,
    ): CancelablePromise<FormWaiverUrl> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/registration-forms/{id}/waivers/{waiverId}/download-url',
            path: {
                'id': id,
                'waiverId': waiverId,
            },
        });
    }

    /**
     * Loads the confirmation message for the registration form
     * @param id
     * @returns FormMessage Success
     * @throws ApiError
     */
    public static getConfirmationMessage(
        id: number,
    ): CancelablePromise<FormMessage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/registration-forms/{id}/message',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Loads the capacity details for a form
     * @param id
     * @returns ParticipantGroupCount Success
     * @throws ApiError
     */
    public static getCapacityCounts(
        id: number,
    ): CancelablePromise<Array<ParticipantGroupCount>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/registration-forms/{id}/capacity-counts',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Returns form adjustment by coupon code for a form
     * @param id
     * @param couponCode
     * @returns FormAdjustment Success
     * @throws ApiError
     */
    public static getFormAdjustmentByCouponCode(
        id: number,
        couponCode: string,
    ): CancelablePromise<FormAdjustment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/registration-forms/{id}/adjustments/{couponCode}',
            path: {
                'id': id,
                'couponCode': couponCode,
            },
        });
    }

    /**
     * Loads a registration form by id
     * @param id
     * @returns RegistrationForm Success
     * @throws ApiError
     */
    public static getForm(
        id: number,
    ): CancelablePromise<RegistrationForm> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/registration-forms/{id}',
            path: {
                'id': id,
            },
        });
    }

}
