import { Comparison, FormField, HouseholdPerson, Registration } from 'core/api';

export interface Values {
  [key: number]: string | string[];
}

export const getAgeFromBirthdate = (dateString: string) => {
  if (!dateString || dateString === '') {
    return;
  }

  const today = new Date();
  const birthDate = new Date(dateString);
  const month = today.getMonth() - birthDate.getMonth();

  let age = today.getFullYear() - birthDate.getFullYear();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

export const getUSDFormat = (amount: string | number) => {
  const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return dollarUS.format(+amount);
};

export const buildComparisonExpression = (left: string | string[], operator: string, right: string): boolean => {
  if (Array.isArray(left)) {
    switch (operator) {
      case Comparison.EQ:
        return left.length === 1 && left[0] === right;
      case Comparison.NEQ:
        return left.length !== 1 || left[0] !== right;
      case Comparison.CONTAINS:
        return left.includes(right);
      case Comparison.NOT_CONTAINS:
        return !left.includes(right);
      default:
        return false;
    }
  } else {
    switch (operator) {
      case Comparison.EQ:
        return left === right;
      case Comparison.NEQ:
        return left !== right;
      case Comparison.GT:
        return left > right;
      case Comparison.GTE:
        return left >= right;
      case Comparison.LT:
        return left < right;
      case Comparison.LTE:
        return left <= right;
      case Comparison.CONTAINS:
        return left.includes(right);
      case Comparison.NOT_CONTAINS:
        return !left.includes(right);
      default:
        return false;
    }
  }
};

export const isVisible = (rules: FormField['visibilityRules'] | undefined, values: Values) => {
  if (!rules || rules.length === 0) {
    return true;
  }

  const checks = rules.map((rule) => {
    return buildComparisonExpression(values[rule.targetFormFieldId], rule.comparison, rule.answerToMatch);
  });

  return rules[0].chainingComparison === 'and' ? !checks.some((c) => !c) : checks.some((c) => c);
};

export function toShortDollar(pennies: number) {
  return (pennies / 100).toFixed(pennies % 100 === 0 ? 0 : 2);
}

export function totalLineItems(registration: Registration) {
  return getUSDFormat(registration.lineItems.reduce((total, item) => total + item.amount, 0) / 100);
}

export function getRegFeeLineItem(registration: Registration) {
  return registration.lineItems.find((l) => l.isRegistrationFee);
}

export const sortPersonByAgeThenFirstName = (a: HouseholdPerson, b: HouseholdPerson) => {
  const ageA = a.person.age === 0 ? 0 : a.person.age || 99;
  const ageB = b.person.age === 0 ? 0 : b.person.age || 99;
  if (ageA === ageB) {
    return a.person.firstName.localeCompare(b.person.firstName);
  }
  return ageA - ageB;
};
