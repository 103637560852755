/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrder } from '../models/CreateOrder';
import type { Order } from '../models/Order';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrdersService {

    /**
     * Creates or updates an order
     * @param requestBody
     * @returns Order Success
     * @throws ApiError
     */
    public static createOrder(
        requestBody?: CreateOrder,
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orders/create-order',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Error`,
            },
        });
    }

}
