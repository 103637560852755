import * as React from 'react';

interface Props {
  bgColor?: string;
  borderColor?: string;
  children: React.ReactNode;
  classes?: string;
  error?: boolean;
  errorMessage?: string;
  rounded?: boolean;
  onClick?: () => void;
}

export const PaddedContentArea = ({
  bgColor,
  borderColor,
  children,
  classes,
  error,
  errorMessage,
  rounded,
  onClick
}: Props) => {
  const allClasses = [
    'u-flex u-padLg u-border u-borderColorNeutral',
    rounded ? 'u-borderRadiusLg' : '',
    borderColor ? borderColor : '',
    error ? 'u-borderColorNegative' : '',
    bgColor ? bgColor : '',
    classes ? classes : '',
  ]
    .join(' ')
    .replace(/\s+/g, ' ');

  const containerProps = () => {
    if (onClick) {
      return {
        role: 'button',
        tabIndex: 0,
        onClick: onClick,
        onBlur: () => {}
      }
    }

    return null;
  }

  return (
    <div
      data-testid="padded-content-area"
      {...containerProps()}
    >
      <div
        className={allClasses}
        data-testid="padded-content-area-body"
      >
        {children}
      </div>

      <div className="u-colorNegative u-spaceEndsSm">{errorMessage}</div>
    </div>
  )
}
