export type DiscountStorage = {
  discounts: string[];
  formResults: number[];
  formId: number;
}

const discountStorageKey = (formId: number) => {
  return `form-${formId}-discounts`;
};

export const discountsFromLocalStorage = (formId: number, formResultIds: number[] | null): string[] => {
  try {
    const rawDiscountStorage = localStorage.getItem(discountStorageKey(formId));
    if (rawDiscountStorage) {
      const discountStorage: DiscountStorage = JSON.parse(rawDiscountStorage);
      if (!discountStorage) return [];
      if (!formResultIds || formResultIds.length === 0) return discountStorage.discounts;

      const intersection = discountStorage.formResults.filter((value) => formResultIds.includes(value))
      if (intersection.length === 0) {
        localStorage.removeItem(discountStorageKey(formId));
      } else {
        return discountStorage.discounts;
      }
    }
  } catch (e) {
    console.error('Error trying to get discount codes');
  }
  return [];
};

export const saveDiscountsToLocalStorage = (formId: number, discounts: string[], formResultIds: number[]) => {
  const rawDiscountStorage = localStorage.getItem(discountStorageKey(formId));
  const discountStorage = rawDiscountStorage
    ? JSON.parse(rawDiscountStorage)
    : {
        discounts: [],
        formResults: formResultIds,
        formId: formId,
      };
  discountStorage.discounts = discounts.sort();

  try {
    localStorage.setItem(discountStorageKey(formId), JSON.stringify(discountStorage));
  } catch {
    console.error('Error trying to save discount codes');
  }
};
