import { Registration, RegistrationUpdate } from 'core/api';
import React from 'react';
import { useFormSelector } from 'state/form/formSlice';
import {
  loadWaiverResults,
  useCurrentFormRegistrations,
  useRegistrationSelector,
  useWaiversSelector,
} from 'state/registration/registrationSlice';
import { useAppDispatch } from '.';

export const useFormWaiverHelpers = (): {
  hasCompletedWaivers?: boolean; // remains undefined until all results load
  cartEmpty: boolean;
} => {
  const form = useFormSelector();
  const registrationData = useRegistrationSelector();
  const currentFormRegistrations = useCurrentFormRegistrations();
  const dispatch = useAppDispatch();
  const [hasCompletedWaivers, sethasCompletedWaivers] = React.useState<boolean | undefined>();
  const [waiversLoaded, setWaiversLoaded] = React.useState<boolean>(false);
  const [cartEmpty, setCartEmpty] = React.useState<boolean>(false);
  const registrationWaivers = useWaiversSelector();
  const formWaivers = form?.waivers || [];
  const registrationIdsInCart = currentFormRegistrations
    ?.filter((reg: Registration) => reg.status === RegistrationUpdate.status.IN_CART)
    .map((reg: Registration) => reg.id);

  React.useEffect(() => {
    if (registrationIdsInCart) {
      const loadAll = registrationIdsInCart.map((id: number) => {
        return dispatch(loadWaiverResults(id));
      });

      Promise.all(loadAll).then((waivers) => {
        setWaiversLoaded(true);
      });
    }
    if (registrationIdsInCart?.length === 0) {
      setCartEmpty(true);
    }
  }, [registrationData?.registrations]);

  React.useEffect(() => {
    if (waiversLoaded && registrationIdsInCart && registrationWaivers) {
      // build object for faster lookup
      const waiverHash = registrationWaivers?.reduce((a, w) => {
        a[`${w.registrationId}_${w.formWaiverId}`] = true;
        return a;
      }, {} as Record<string, boolean>);

      // check each waiver needed for each participant
      const isComplete = registrationIdsInCart.every((registrationId) =>
        formWaivers?.every((waiver) => {
          return !!waiverHash[`${registrationId}_${waiver.id}`];
        })
      );
      sethasCompletedWaivers(isComplete);
    }
  }, [waiversLoaded, registrationIdsInCart, registrationWaivers]);

  return {
    hasCompletedWaivers,
    cartEmpty,
  };
};
