/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FormAdjustment = {
    id: number;
    formFieldId: number | null;
    definition: {
        name: string;
        category: string;
    };
    answerToMatch: string | null;
    amount: number | null;
    maxQuantity: number;
    maxAmount: number;
    percentage: number | null;
    appliesTo: FormAdjustment.appliesTo | null;
    couponCode: string | null;
};

export namespace FormAdjustment {

    export enum appliesTo {
        REGISTRATION_FEE = 'registration_fee',
        FEES = 'fees',
        ALL = 'all',
    }


}

