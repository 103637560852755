/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Registration = {
    id: number;
    formId: number;
    householdPersonId: number;
    participantGroupId: number;
    status: Registration.status;
    waitlistStatus: Registration.waitlistStatus | null;
    checkoutStartTime: string | null;
    submittedAt: string | null;
    orderId: number | null;
    lineItems: Array<{
        name: string;
        basePrice: number;
        quantity: number;
        amount: number;
        isRegistrationFee: boolean;
        scopeId: number;
        scopeType: string;
        category?: string | null;
    }>;
};

export namespace Registration {

    export enum status {
        IN_PROGRESS = 'in_progress',
        SUBMITTED = 'submitted',
        CANCELED = 'canceled',
        IN_CART = 'in_cart',
        DELETED = 'deleted',
        CHECKOUT = 'checkout',
    }

    export enum waitlistStatus {
        WAITLISTED = 'waitlisted',
        OFFER_EXTENDED = 'offer_extended',
        OFFER_ACCEPTED = 'offer_accepted',
        ENROLLED = 'enrolled',
        RELEASED = 'released',
    }


}

