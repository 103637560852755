import * as React from 'react';
import { Button, Modal } from '@teamsnap/teamsnap-ui';

// Local Imports
import { useHouseholdPeopleSelector } from 'state/households/householdSlice';
import { RadioGroup, RadioOption } from '../RadioGroup/RadioGroup';
import { useCurrentMemberSelector } from 'state/registration/registrationSlice';
import { HouseholdPerson } from 'core/api';

export interface GuardianSelectProps {
  onClose: () => void;
  onGuardianSelect: (householdPerson: HouseholdPerson) => void;
}

const renderSubheading = (participant: HouseholdPerson) => {
  const {
    person: { age },
  } = participant;

  if (age) {
    if (!age && age !== 0) {
      return '';
    }

    if (age >= 18) {
      return 'Adult';
    }

    return `Age ${age}`;
  }

  return 'No Birthdate';
};

export const GuardianSelect = ({ onClose, onGuardianSelect }: GuardianSelectProps) => {
  const [selectedGuardian, setSelectedGuardian] = React.useState<RadioOption | null>(null);
  const currentMember = useCurrentMemberSelector();
  let guardians = useHouseholdPeopleSelector();

  if (guardians) {
    guardians = guardians?.filter((guardian) => guardian !== currentMember);
  }

  return (
    <span className="modal-wrapper--nohead">
      <Modal heading={''} showClose={false} show={true}>
        <div className="sui-flex sui-flex-col">
          <h3 className="sui-heading-md sui-p-2">Choose a Guardian</h3>
          <div className="scrollable-content-container">
            <div className="sui-px-3 sui-pb-4">
              <RadioGroup
                name="guardianSelect"
                options={
                  guardians
                    ? guardians.map((guardian) => {
                        return {
                          label: (
                            <>
                              {guardian.person.firstName} {guardian.person.lastName}
                              <span className=" sui-text-gray-8 sui-pl-1">({renderSubheading(guardian)})</span>
                            </>
                          ),
                          value: guardian.id,
                        };
                      })
                    : []
                }
                onRadioSelect={(person: RadioOption) => setSelectedGuardian(person)}
              />
            </div>
          </div>
          <div className="sui-flex sui-tems-center sui-justify-end sui-p-2">
            <Button
              key="add-another-member"
              mods="sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              mods="sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1 sui-ml-1"
              isDisabled={false}
              onClick={() => {
                onGuardianSelect(
                  guardians?.find((guardian) => guardian.id === selectedGuardian?.value) as HouseholdPerson
                );
                onClose();
              }}
            >
              Choose
            </Button>
          </div>
        </div>
      </Modal>
    </span>
  );
};
