import { Cart } from 'components/Form';
import React from 'react';
import { useFormSelector } from 'state/form/formSlice';

export const CartPage = () => {
  const form = useFormSelector();

  if (!form) {
    return null;
  }

  return <Cart formId={form?.id} formWaivers={form?.waivers} />;
};
