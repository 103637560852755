import * as React from 'react';
import { Button } from '@teamsnap/teamsnap-ui';
import { FeatureFlagService } from 'frontend-toolkit';

// Local imports
import { ActionContainer } from 'components/shared';
import { Header } from '../Header/Header';
import { WaiverData } from '../SignWaivers/SignWaivers';
import { CartButton } from 'components/shared/CartButton';
import { Banner } from 'components/shared/Banner';
import { MemberCartDetails } from '../MemberCartDetails/MemberCartDetails';
import { getUSDFormat } from 'core/utilities';
import { RegistrationWithDiscounts } from 'core/api';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import { CartWarningModal } from 'components/CartWarningModal/CartWarningModal';
import { DiscountsCartSection, useDiscounts } from 'components/Form';

import { FeatureFlagConfig, FeatureFlags } from 'core/feature-flags';
import ENVIRONMENT from 'core/environment';

// Redux imports
import {
  useCartHasGroupsWithCapacityLimitSelector,
  useRegistrationsAllWaitlistedSelector,
  useRegistrationsAtCapacitySelector,
  useRegistrationsFromInactiveParticipantGroupsSelector,
} from 'state/registration/registrationSelectors';
import { useUserSelector } from 'state/user/userSlice';
import { useAppDispatch, useAppNavigate } from 'state/hooks';
import {
  loadRegistrations,
  loadRegistrationsWithDiscounts,
  useInCartRegistrationsSelector,
} from 'state/registration/registrationSlice';
import { useHouseholdPeopleSelector } from 'state/households/householdSlice';
import { useFormSelector } from 'state/form/formSlice';
import { useRegistrationsWithDiscountsSelector } from 'state/registration/registrationSlice';
import { InvalidDiscountWarningModal } from 'components/InvalidDiscountWarningModal/InvalidDiscountWarningModal';

interface Props {
  formId: number;
  formWaivers: WaiverData[];
}

export const Cart = ({ formId, formWaivers }: Props) => {
  const [showCartWarningModal, setShowCartWarningModal] = React.useState<boolean>(false);
  const [showInvalidDiscountModal, setShowInvalidDiscountModal] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();

  const user = useUserSelector();
  const navigate = useAppNavigate();
  const members = useHouseholdPeopleSelector();
  const inCartRegistrations = useInCartRegistrationsSelector() || [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [discounts, setDiscounts, discountErrors, removeDiscount, discountsAmount, invalidDiscountCodes] = useDiscounts(
    formId,
    inCartRegistrations.map((reg) => reg.id)
  );

  const queryParameters = new URLSearchParams(window.location.search);
  const displayWarning = queryParameters.get('warning');
  const error = queryParameters.get('error');

  const cartHasGroupsWithCapacityLimit = useCartHasGroupsWithCapacityLimitSelector();
  const registrationsAtCapacity = useRegistrationsAtCapacitySelector();
  const allInCartRegistrationsWaitlisted = useRegistrationsAllWaitlistedSelector();
  const registrationsWithInactiveGroup = useRegistrationsFromInactiveParticipantGroupsSelector();
  const registrationsWithDiscounts: RegistrationWithDiscounts =
    useRegistrationsWithDiscountsSelector() as RegistrationWithDiscounts;

  const handleInvalidDiscountModalClose = () => {
    navigate(`/form/${formId}/cart`);
    setShowInvalidDiscountModal(false);
  };

  React.useEffect(() => {
    if (
      (displayWarning !== 'false' &&
        (registrationsAtCapacity.atCapacityWithWaitlistAndModalInfoNeeded.length > 0 ||
          registrationsAtCapacity.atCapacityWithoutWaitlist.length > 0)) ||
      registrationsWithInactiveGroup.length > 0
    )
      setShowCartWarningModal(true);
  }, [
    registrationsAtCapacity?.atCapacityWithWaitlistAndModalInfoNeeded?.length,
    registrationsAtCapacity?.atCapacityWithoutWaitlist?.length,
    registrationsWithInactiveGroup?.length,
  ]);

  React.useEffect(() => {
    dispatch(loadRegistrations());
  }, []);

  const form = useFormSelector();
  const { organizationName, organizationId } = form || {};

  // TODO: Remove Feature Flag once feature is fully released.
  const capacityLimitsAreEnabled = FeatureFlagService.isFeatureEnabledForOrg(
    FeatureFlagConfig[FeatureFlags.PARTICIPANT_GROUP_CAPACITY_LIMIT],
    ENVIRONMENT,
    organizationId
  );

  const cartMembers = members?.filter((member) =>
    inCartRegistrations?.find((reg) => reg.householdPersonId === member.id)
  );

  const calculateCartTotal = () => {
    let subtotal = inCartRegistrations.flatMap((r) => r.lineItems).reduce((total, item) => total + item.amount, 0) || 0;
    subtotal = Math.sign(subtotal) === -1 ? 0 : subtotal;

    if (discountsAmount > 0) {
      return getUSDFormat((subtotal - discountsAmount) / 100);
    }

    return getUSDFormat(subtotal / 100);
  };

  const calculateCartDiscountTotal = () => {
    const discountTotal = discountsAmount / 100;
    return discountTotal > 0 ? `-${getUSDFormat(discountTotal)}` : getUSDFormat(discountTotal);
  };

  const goToNextPage = async () => {
    const localDiscounts = JSON.parse(localStorage.getItem(`form-${formId}-discounts`) ?? '{}')?.discounts;

    const response = await dispatch(
      loadRegistrationsWithDiscounts({ formId: +formId, couponCodes: (localDiscounts ?? []).join(',') })
    ).unwrap();

    const loadedDiscounts = response?.data?.discounts || [];
    const invalidLocalDiscountCodes = localDiscounts?.filter(
      (discount: string) => !loadedDiscounts.find((d) => d.name === discount)
    );

    if (invalidLocalDiscountCodes?.length > 0) {
      setShowInvalidDiscountModal(true);

      return false;
    }

    if (formWaivers.length === 0) {
      navigate(`/form/${formId}/payment`);
    } else {
      navigate(`/form/${formId}/agreements`);
    }
  };

  const handleDiscountEntry = (couponCode: string) => {
    const existingDiscounts = registrationsWithDiscounts?.discounts?.map((discount) => discount.name) || [];

    setDiscounts(Array.from(new Set([...existingDiscounts, couponCode])));
  };

  return (
    <>
      <ActionContainer
        submitting={false}
        removeContentFormatting={true}
        header={
          <Header
            title={organizationName ?? 'Cart'}
            navigation={
              <Button
                iconPosition="left"
                mods="back-button sui-m-0 sui-w-auto sui-text-gray-10 t:sui-hidden"
                icon="arrow-left"
                type="link"
                size="large"
                onClick={() => navigate(`/form/${formId}/answerQuestions`)}
              />
            }
            rightIcon={<CartButton />}
            profileName={`${user?.firstName} ${user?.lastName}`}
          />
        }
        extraFooter={
          <div className="sui-w-full sui-text-gray-8 sui-flex sui-justify-between sui-p-1 t:sui-px-3 sui-body sui-max-w sui-mx-auto sui-shadow-up t:sui-hidden">
            <span>Subtotal</span>
            <span data-testid="subtotal-amount">{calculateCartTotal()}</span>
          </div>
        }
        footer={
          <div className="t:sui-flex t:items-center t:sui-justify-between">
            <Button
              key="back"
              mods="sui-hidden t:sui-flex sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1 sui-hidden t:sui-flex"
              icon="arrow-left"
              iconPosition="left"
              onClick={() => navigate(`/form/${formId}/selectGroup`)}
            >
              <span data-testid="back-button">Back</span>
            </Button>

            <Button
              key="check-out"
              color="primary"
              mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
              icon="arrow-right"
              iconPosition="right"
              isDisabled={inCartRegistrations.length === 0}
              onClick={goToNextPage}
            >
              <span data-testid="checkout-button">Check Out</span>
            </Button>
          </div>
        }
        children={
          <div className="sui-flex sui-flex-col">
            <InvalidDiscountWarningModal isOpen={showInvalidDiscountModal} onClose={handleInvalidDiscountModalClose} />

            {error && <ErrorMessage message="Something went wrong!" />}
            {cartHasGroupsWithCapacityLimit && capacityLimitsAreEnabled && !allInCartRegistrationsWaitlisted && (
              <Banner
                rounded={true}
                type="warning"
                classes="u-padMd sui-mb-2 sui-mt-2"
                children="Payment required to secure reserved spots."
              />
            )}
            {cartMembers && cartMembers.length > 0 ? (
              <>
                <h3 className="u-spaceTopMd" data-testid="cart-header">
                  Review Selections
                </h3>
                <small className="u-padTopSm u-spaceBottomMd">Here is a summary of your registration so far.</small>
                {cartMembers?.map((member, index) => (
                  <MemberCartDetails
                    key={index}
                    member={member}
                    appliedDiscounts={registrationsWithDiscounts.discounts || []}
                    registrations={inCartRegistrations}
                    registrationsAtMaxCapacity={[
                      ...registrationsAtCapacity.atCapacityWithoutWaitlist,
                      ...registrationsAtCapacity.atCapacityWithWaitlistAndModalInfoNeeded,
                    ]}
                    registrationsWithInactiveGroup={registrationsWithInactiveGroup}
                    waitlistedRegistrations={registrationsAtCapacity.atCapacityWithWaitlistAndModalInfoNeeded}
                  />
                ))}

                <div className="sui-p-1 t:sui-p-0 t:sui-flex t:items-center t:sui-justify-end sui-my-2">
                  <Button
                    key="add-another-member-btn"
                    mods="sui-w-full t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
                    icon="plus"
                    iconPosition="left"
                    onClick={() => navigate(`/form/${formId}/selectParticipant`, { replace: true })}
                  >
                    <span data-testid="add-participant">Register Another Participant</span>
                  </Button>
                </div>

                {form?.hasCouponCodeDiscount ||
                registrationsWithDiscounts?.discounts?.length > 0 ||
                invalidDiscountCodes.length > 0 ? (
                  <>
                    <hr className="sui-my-2" />
                    <div>
                      <DiscountsCartSection
                        error={discountErrors[0]}
                        onSave={handleDiscountEntry}
                        appliedDiscounts={registrationsWithDiscounts.discounts || []}
                        onDeleteDiscount={(couponCode) => removeDiscount(couponCode)}
                        invalidDiscountCodes={invalidDiscountCodes}
                        formHasCouponCodeDiscounts={form?.hasCouponCodeDiscount}
                      />
                    </div>
                  </>
                ) : null}

                <hr className="sui-my-2" />
                {discountsAmount > 0 && (
                  <div className="sui-hidden sui-mt-2 sui-py-2 sui-w-full sui-flex sui-justify-between sui-p-1 t:sui-px-3 sui-body sui-max-w sui-mx-auto t:sui-flex">
                    <span
                      data-testid="cart-discount-heading"
                      className="sui-font-size-7 sui-font-semibold sui-text-gray-8"
                    >
                      Discount Total
                    </span>

                    <span
                      data-testid="cart-discount-value"
                      className="sui-font-size-7 sui-font-semibold sui-text-gray-8"
                    >
                      {calculateCartDiscountTotal()}
                    </span>
                  </div>
                )}

                <div className="sui-hidden sui-py-2 sui-w-full sui-flex sui-justify-between sui-p-1 t:sui-px-3 sui-body sui-max-w sui-mx-auto t:sui-flex sui-mb-5">
                  <span data-testid="cart-total-heading" className="sui-font-size-7 sui-font-bold">
                    Total
                  </span>

                  <span data-testid="cart-total-value" className="sui-font-size-7 sui-font-bold sui-ml-2">
                    {calculateCartTotal()}
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="sui-py-2 sui-px-3 sui-mt-2 sui-text-center">
                  <p className="sui-body sui-py-4" data-testid="empty-cart">
                    You have nothing in your cart.
                  </p>
                </div>
                <div className="sui-p-1 t:sui-p-0 t:sui-flex t:items-center t:sui-justify-end sui-my-1">
                  <Button
                    key="add-another-member-btn"
                    mods="sui-w-full sui-max-w-sm sui-mx-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
                    icon="plus"
                    iconPosition="left"
                    onClick={() => navigate(`/form/${formId}/selectParticipant`, { replace: true })}
                  >
                    <span data-testid="add-participant">Add Participant</span>
                  </Button>
                </div>
              </>
            )}
          </div>
        }
      />
      <CartWarningModal
        registrationsAtCapacity={registrationsAtCapacity}
        registrationsWithInactiveGroup={registrationsWithInactiveGroup}
        isOpen={showCartWarningModal}
        onClose={() => {
          setShowCartWarningModal(false);
          navigate(`/form/${form?.id}/cart`);
        }}
      />
    </>
  );
};
