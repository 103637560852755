import { useEffect, useState } from 'react';

interface Props {
  name?: string;
  id?: string;
  value: number | null;
  onChange: (n: number | null) => unknown;
  className?: string;
  onBlur?: (e: React.FocusEvent<unknown, unknown>) => unknown;
  label?: string | JSX.Element;
}

const dollarRegex = /^[0-9,.]*$/g;

export const DollarInput = ({ onChange, className, value, onBlur, name, id, label }: Props) => {
  const [state, setState] = useState(value ? value.toString() : null);

  useEffect(() => {
    setState(Number.isNaN(value) ? null : convertFromPennies(Math.abs(value as number)));
  }, [value]);

  const change = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const newValue = e.target.value;
    if (newValue.match(dollarRegex) && !newValue.startsWith(',')) {
      setState(newValue);
    }
  };

  const publishChange = () => {
    let newValue = null;
    const stripped = state?.replace(/,/g, '');
    if (!Number.isNaN(stripped)) {
      newValue = Math.round(Number(Number(stripped).toFixed(2)) * 100);
    }

    onChange(newValue);
  };

  const inputOnBlur = (e: React.FocusEvent<HTMLInputElement, unknown>) => {
    publishChange();
    if (onBlur) {
      onBlur(e);
    }
  };

  const keyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      publishChange();
      e.stopPropagation();
    }
  };

  return (
    <>
      <label htmlFor={name} className="sui-label">
        {label}
      </label>
      <input
        name={name}
        id={id}
        onBlur={inputOnBlur}
        onKeyUp={keyPress}
        value={state || ''}
        onChange={change}
        className={className ? 'Input ' + className : 'Input'}
      />
    </>
  );
};

export function convertFromPennies(value: number): string | null {
  if (value === null || value === undefined) return null;

  const [number, decimal] = (value / 100).toFixed(2).split('.');
  const numberWithCommas = number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return `${numberWithCommas}.${decimal}`;
}
