import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HouseholdPerson, HouseholdsService, PeopleService } from 'core/api';
import { CreateHouseholdPerson } from 'core/api/models/CreateHouseholdPerson';
import { UpdatePerson } from 'core/api/models/UpdatePerson';
import { AsyncState } from 'core/types';
import { asyncPendingReducer, handleErrors, useAppSelector, modifySubState } from 'state/hooks';
import { HouseholdsState } from './types';
import { sortPersonByAgeThenFirstName } from 'core/utilities';

const initialState: AsyncState<HouseholdsState> = {
  data: null,
  processing: false,
  error: false,
  errors: [],
};

export const loadHouseholdsAndPeople = createAsyncThunk('households/loadAll', () =>
  handleErrors(async () => await HouseholdsService.getHouseholdsAndPersons())
);

export const createHouseholdPerson = createAsyncThunk(
  'household/create',
  async (data: { householdId: number; body: CreateHouseholdPerson }) => {
    const { householdId, body } = data;

    return handleErrors(async () => {
      return await HouseholdsService.createHouseholdPerson(householdId, body);
    });
  }
);

export const createHouseholdAndPerson = createAsyncThunk(
  'household/createWithHoushold',
  async (data: { body: CreateHouseholdPerson }) => {
    const { body } = data;

    return handleErrors(async () => {
      return await HouseholdsService.createHouseholdAndPerson(body);
    });
  }
);

export const editHouseholdPerson = createAsyncThunk(
  'household/edit',
  async (data: { id: number; body: UpdatePerson }) => {
    const { id, body } = data;

    return handleErrors(async () => {
      return await PeopleService.updatePerson(id, body);
    });
  }
);

export const deleteHouseholdPerson = createAsyncThunk(
  'household/delete',
  async (data: { householdId: number; householdPersonId: number }) => {
    const { householdId, householdPersonId } = data;

    return handleErrors(async () => {
      await HouseholdsService.deleteHouseholdPerson(householdId, householdPersonId);
      return householdPersonId;
    });
  }
);

export const householdSlice = createSlice({
  name: 'householdData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadHouseholdsAndPeople.pending, asyncPendingReducer);
    builder.addCase(loadHouseholdsAndPeople.fulfilled, (state, action) => {
      const updatedState = {
        households: action.payload.data?.households,
        householdPeople: action.payload.data?.householdPeople,
      };
      modifySubState(state, action, updatedState);
    });

    builder.addCase(createHouseholdPerson.pending, asyncPendingReducer);
    builder.addCase(createHouseholdPerson.fulfilled, (state, action) => {
      const newHouseholdPerson = action.payload.data;

      const updatedState = newHouseholdPerson
        ? {
            householdPeople: [...(state.data?.householdPeople || []), newHouseholdPerson],
          }
        : {};

      modifySubState(state, action, updatedState);
    });
    builder.addCase(createHouseholdAndPerson.pending, asyncPendingReducer);
    builder.addCase(createHouseholdAndPerson.fulfilled, (state, action) => {
      const newHouseholdPerson = action.payload.data?.householdPerson;
      const newHousehold = action.payload.data?.household;
      const cuurentHouseholdPeople = [...(state.data?.householdPeople || [])];
      const cuurentHouseholds = [...(state.data?.households || [])];

      const updatedState = {
        householdPeople: newHouseholdPerson ? [...cuurentHouseholdPeople, newHouseholdPerson] : cuurentHouseholdPeople,
        households: newHousehold ? [...cuurentHouseholds, newHousehold] : cuurentHouseholds,
      };
      modifySubState(state, action, updatedState);
    });
    builder.addCase(editHouseholdPerson.pending, asyncPendingReducer);
    builder.addCase(editHouseholdPerson.fulfilled, (state, action) => {
      const householdPeople = [...(state.data?.householdPeople || [])].map((hp) => {
        if (hp.person.id === action.payload.data?.id) {
          return {
            ...hp,
            person: action.payload.data,
          };
        }
        return hp;
      });
      const updatedState = {
        householdPeople,
      };
      modifySubState(state, action, updatedState);
    });
    builder.addCase(deleteHouseholdPerson.pending, asyncPendingReducer);
    builder.addCase(deleteHouseholdPerson.fulfilled, (state, action) => {
      const householdPeople = [...(state.data?.householdPeople || [])].filter((hp) => hp.id !== action.payload.data);
      const updatedState = {
        householdPeople,
      };
      modifySubState(state, action, updatedState);
    });
  },
});

export const useHouseholdsSelector = () => useAppSelector((state) => state.household.data?.households);

export const useHouseholdPeopleSelector = () => useAppSelector((state) => state.household.data?.householdPeople);
export const usePrimaryHouseholdIdSelector = () =>
  useAppSelector(
    (state) =>
      state.household.data?.householdPeople?.find(
        (hp) => hp?.isOrganizer && hp?.person.userId === state.user.data?.user?.id
      )?.householdId
  );
export const useHouseholdOrganizersSelector = () =>
  useAppSelector((state) => state.household.data?.householdPeople?.filter((member) => member.isOrganizer) || []);

export const useGuardiansSelector = () =>
  useAppSelector((state) =>
    state.household.data?.householdPeople?.filter((member) => {
      const {
        person: { age },
      } = member;
      if (age) {
        if (age && age >= 18) {
          return true;
        }
      }
      return false;
    })
  );

export const useGroupedHouseholdPeopleSelector = (): Record<number, HouseholdPerson[]> =>
  useAppSelector(
    (state) =>
      state.household.data?.householdPeople?.reduce((acc, member: HouseholdPerson) => {
        if (acc[member.householdId]) {
          acc[member.householdId] = [...acc[member.householdId], member].sort(sortPersonByAgeThenFirstName);
        } else {
          acc[member.householdId] = [member];
        }
        return acc;
      }, {} as Record<number, HouseholdPerson[]>) || {}
  );

export const useHouseholdErrorSelector = () => useAppSelector((state) => state.household.error);
export const useHouseholdErrorsSelector = () => useAppSelector((state) => state.household.errors);

export default householdSlice.reducer;
