import * as React from 'react';

// Local imports
import { AnswerQuestions } from 'components/Form/AnswerQuestions/AnswerQuestions';
import { useCurrentRegistration } from 'state/registration/registrationSlice';
import { useAppNavigate } from 'state/hooks';
import { useFormSelector } from 'state/form/formSlice';

export const AnswerQuestionsPage = () => {
  const form = useFormSelector();
  const navigate = useAppNavigate();
  const currentRegistrataion = useCurrentRegistration();

  React.useEffect(() => {
    if (!currentRegistrataion) {
      navigate(`/form/${form?.id}/selectParticipant`);
    }
  }, []);

  return <AnswerQuestions />;
};
