import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { ActionContainer } from 'components/shared';
import { FormField, FormFieldType } from 'components/Form';
import * as validations from 'core/validations';
import { useAppDispatch, useAppNavigate } from 'state/hooks';
import { useLoginEmailSelctor, resetPassword } from 'state/user/userSlice';
import { useFormSelector } from 'state/form/formSlice';

import logoImage from '../../assets/images/logo.svg';
import { useState } from 'react';

const schema = Yup.object({
  email: validations.EMAIL,
});

export const ResetPassword = () => {
  const [message, setMessage] = useState<string | undefined>();
  const dispatch = useAppDispatch();
  const form = useFormSelector();
  const email = useLoginEmailSelctor();
  const navigate = useAppNavigate();

  return (
    <div className="EmailEntry">
      <Formik
        initialValues={{
          email: email || '',
        }}
        validationSchema={schema}
        onSubmit={async (values, { setFieldError, setSubmitting }) => {
          if (message) {
            navigate(`/form/${form?.id}`);
          }
          const result = await dispatch(resetPassword(values.email)).unwrap();

          if (result.error) {
            setSubmitting(false);
            setFieldError(
              'email',
              'Oh no! It looks like something went wrong. Please try again or contact TeamSnap Support for help.'
            );
          } else {
            setMessage('Check your inbox for password reset link.');
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <ActionContainer
            action={handleSubmit}
            actionLabel={message ? 'Back to Login' : 'Send'}
            title={form?.organizationName || 'Registration'}
            submitting={isSubmitting}
          >
            <Form>
              <div className="sui-flex sui-justify-center sui-mb-3">
                <img className="logo-image" src={logoImage} alt="TeamSnap" />
              </div>

              <h1 className="sui-heading-md">{form?.name || form?.program.name || form?.organizationName}</h1>

              <p className="sui-body sui-text-gray-8 sui-mb-3">
                Enter your account email address to get a reset password link.
              </p>

              {message ? (
                <p className="sui-body sui-text-gray-8 sui-mb-3">{message}</p>
              ) : (
                <FormField type={FormFieldType.EMAIL} label="Email" name="email" placeholder="email@email.com" />
              )}
            </Form>
          </ActionContainer>
        )}
      </Formik>
    </div>
  );
};
