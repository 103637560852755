import * as React from 'react';
import { ValidationError } from 'core/api';

// Local Imports
import { ConfirmationModal } from 'components/shared/ConfirmationModal';
import { useUserStateErrorSelector, useUserStateErrorsSelector } from 'state/user/userSlice';
import {
  useRegistrationStateErrorSelector,
  useRegistrationStateErrorsSelector,
} from 'state/registration/registrationSlice';
import { useHouseholdErrorSelector, useHouseholdErrorsSelector } from 'state/households/householdSlice';
import { useFormStateErrorSelector, useFormStateErrorsSelector } from 'state/form/formSlice';

export const ErrorHandler = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');

  const userError = useUserStateErrorSelector();
  const userErrors = useUserStateErrorsSelector();

  const registrationError = useRegistrationStateErrorSelector();
  const registrationErrors = useRegistrationStateErrorsSelector();

  const householdError = useHouseholdErrorSelector();
  const householdErrors = useHouseholdErrorsSelector();

  const formError = useFormStateErrorSelector();
  const formErrors = useFormStateErrorsSelector();

  const handleClose = () => {
    setIsOpen(false);
    window.location.reload();
  };

  const setErrorMessage = (error: string[] | ValidationError[]) => {
    if (Array.isArray(error)) {
      if ((error[0] as ValidationError)?.message) {
        const errorMessage = error.map((error) => (error as ValidationError)?.message).join(' ');
        setMessage(errorMessage);
      } else {
        const errorMessage = error.join(' ');
        setMessage(errorMessage);
      }
    } else {
      setMessage(error);
    }
  };

  const handleState = () => {
    if (userError || registrationError || householdError || formError) {
      setIsOpen(true);
    }

    if (userError) {
      setErrorMessage(userErrors);
    }

    if (registrationError) {
      setErrorMessage(registrationErrors);
    }

    if (householdError) {
      setErrorMessage(householdErrors);
    }

    if (formError) {
      setErrorMessage(formErrors);
    }
  };

  React.useEffect(() => {
    handleState();

    return () => {
      setIsOpen(false);
      setMessage('');
    };
  }, [userError, registrationError, householdError, formError]);

  return (
    <>
      {message && (
        <ConfirmationModal
          dismissText="Dismiss"
          handleClose={handleClose}
          isOpen={isOpen}
          text={message}
          helperText="*Reloading the page may solve this issue."
        />
      )}
    </>
  );
};
