import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import formReducer from './form/formSlice';
import userReducer from './user/userSlice';
import householdReducer from './households/householdSlice';
import registrationReducer from './registration/registrationSlice';

export const store = configureStore({
  reducer: {
    form: formReducer,
    user: userReducer,
    household: householdReducer,
    registration: registrationReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
