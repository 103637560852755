import React from 'react';
import { VerifyMembership } from 'components/Form/NgbIntegrations/VerifyMembership';
import { useFormSelector } from 'state/form/formSlice';
import { useCurrentMemberSelector } from 'state/registration/registrationSlice';
import { useAppNavigate } from 'state/hooks';
import { Banner } from 'components/shared';

export type NgbIntegrationData = {
  id: number;
  name: string;
  code: string;
  metadata: any;
};

export const VerifyMembershipPage = () => {
  const navigate = useAppNavigate();
  const currentForm = useFormSelector();
  const member = useCurrentMemberSelector();
  const { ngbIntegrations } = currentForm || {};

  // only support one NGB integration verification per form
  const selectedNgbIntegration = ngbIntegrations ? ngbIntegrations[0] : null;

  React.useEffect(() => {
    if (!member) {
      navigate(`/form/${currentForm?.id}/selectParticipant`);
    }
  }, [member]);

  if (!currentForm) {
    return null;
  }

  // check if ngb integration data is available, if not throw error and do not proceed
  if (!selectedNgbIntegration || !(selectedNgbIntegration?.ngbIntegration?.metadata?.fields?.length > 0)) {
    return (
      <Banner
        rounded={true}
        type="warning"
        classes="u-padMd sui-mb-2"
        children="Unable to load membership verification for this form."
      />
    );
  }

  return (
    <VerifyMembership
      ngbIntegration={selectedNgbIntegration.ngbIntegration}
      seasonStartDate={selectedNgbIntegration.seasonStartDate}
      seasonEndDate={selectedNgbIntegration.seasonEndDate}
    />
  );
};
