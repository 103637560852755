import * as React from 'react';
import { Button } from '@teamsnap/teamsnap-ui';

import { Accordion, AlphaNumericInput } from 'components/shared';

import { DiscountItem, InvalidDiscountItem } from './DiscountItem';

export type DiscountType = {
  name: string;
  basePrice: number;
  quantity: number;
  amount: number;
  isAutomaticDiscount: boolean;
  formResultId?: number;
  sourceId?: number;
  sourceType?: string;
  scopeId: number;
  scopeType: string;
};

export type DiscountItemType = {
  name: string;
  amount: number;
  isAutomaticDiscount: boolean;
  scopeId: number;
  scopeType: string;
};

type Props = {
  appliedDiscounts: DiscountType[];
  onDeleteDiscount(discount: string): void;
  invalidDiscountCodes?: string[];
  error?: string;
  onSave(value: string): void;
  formHasCouponCodeDiscounts?: boolean;
};

export const DiscountsCartSection = ({
  appliedDiscounts,
  onDeleteDiscount,
  invalidDiscountCodes,
  error,
  onSave,
  formHasCouponCodeDiscounts,
}: Props) => {
  const [discount, setDiscount] = React.useState<string>('');

  // Clear state when unmounting
  React.useEffect(() => {
    return () => setDiscount('');
  }, []);

  const handleSubmit = () => {
    onSave(discount);
    setDiscount('');
    setValue('');
  };

  const setValue = (value: string) => {
    const regex = /^[0-9A-Za-z]+$/;

    // If empty, set to empty. This is needed for when deleting the entire string
    if (!value) {
      setDiscount('');
    }

    if (value.match(regex)) {
      const uppercaseValue = value.toUpperCase();

      setDiscount(uppercaseValue);
    }
  };

  const groupedDiscounts = (discounts: DiscountType[]) => {
    const grouped = discounts.reduce<{ [key: string]: DiscountType }>((acc, discount) => {
      const key = `${discount.scopeId}-${discount.scopeType}`;
      if (!acc[key]) {
        acc[key] = {
          ...discount,
          amount: 0,
        };
      }
      acc[key].amount += discount.amount;
      return acc;
    }, {});

    return Object.values(grouped);
  };

  return (
    <Accordion title="Discounts" hasDivider={false}>
      <>
        {/* List of applied discounts */}
        {(appliedDiscounts?.length > 0 || (invalidDiscountCodes && invalidDiscountCodes?.length > 0)) && (
          <div className="sui-my-1 sui-border-t-0 sui-border-l-0 sui-border-r-0 sui-border-solid sui-border-gray-5">
            <div className="sui-mx-3 sui-pb-2">
              {invalidDiscountCodes?.map((invalidDiscount) => (
                <React.Fragment key={invalidDiscount.toLowerCase()}>
                  <InvalidDiscountItem discount={invalidDiscount} onDelete={(discount) => onDeleteDiscount(discount)} />
                </React.Fragment>
              ))}

              {groupedDiscounts(appliedDiscounts).map((discount: DiscountType) => (
                <React.Fragment key={discount.name.toLowerCase()}>
                  <DiscountItem discount={discount} onDelete={(discount) => onDeleteDiscount(discount)} />
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
        {/* Discount entry box for coupon code discounts */}
        {formHasCouponCodeDiscounts && (
          <div className="sui-flex sui-justify-between sui-pt-2 sui-px-1 t:sui-px-2">
            <div className="sui-flex sui-basis-8/12">
              <AlphaNumericInput
                value={discount}
                name="discount"
                onChange={(value: string) => setValue(value)}
                placeholderText="e.g. 10OFF"
                hasError={(error && error.length > 0) || false}
              />
            </div>

            <div className="sui-flex sui-basis-4/12 sui-justify-end">
              <Button
                testId="discount-entry-apply-action"
                size="large"
                type="button"
                color="primary"
                style={{ height: 48 }}
                onClick={handleSubmit}
              >
                <span className="sui-px-1 t:sui-px-3">Apply</span>
              </Button>
            </div>
          </div>
        )}
        {error ? (
          <div className="sui-flex sui-pl-1 t:sui-px-2">
            <span data-testid={`discount-entry-error-${discount}`} className="sui-text-red-40">
              {error}
            </span>
          </div>
        ) : null}
      </>
    </Accordion>
  );
};
