/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OrderLineItem = {
    id: number;
    amount: number;
    name: string;
    type: OrderLineItem.type;
};

export namespace OrderLineItem {

    export enum type {
        REGISTRATION_FEE = 'registration_fee',
        ADJUSTMENT = 'adjustment',
        FEE = 'fee',
        CREDIT = 'credit',
    }


}

