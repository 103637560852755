import { useField } from 'formik';
import { FieldMessage, Statuses } from '@teamsnap/teamsnap-ui';

// Local imports
import { FormAdjustment } from 'core/api';
import { DollarInput } from './DollarInput';
import { toShortDollar } from '../../../core/utilities';

interface Props {
  adjustment: FormAdjustment;
}

export const AdjustmentAmount = ({ adjustment }: Props) => {
  const [field, meta] = useField({
    name: adjustment.id + '-amount',
    validate: (v) => {
      if (isNaN(v)) return 'Amount is required';
      const amount = parseInt(v);
      if (adjustment.amount && amount < adjustment.amount) return `Must be at least $${toShortDollar(adjustment.amount)}`;
      if (amount > adjustment.maxAmount) return `Cannot be more than $${toShortDollar(adjustment.maxAmount)}`;
    },
  });

  const onChange = (value: number | null) => {
    field.onChange({ target: { name: field.name, value: value?.toString() } });
  };

  return (
    <div className="sui-ml-2 sui-w-10">
      <DollarInput
        label={
          <span>
            Amount<span className="sui-text-red-4">*</span>
          </span>
        }
        id={field.name}
        name={field.name}
        value={Number.isNaN(field.value) ? null : parseInt(field.value)}
        onChange={onChange}
        className="sui-h-6 sui-px-1 sui-py-1 sui-body"
      />
      <FieldMessage mods="sui-caption" status={Statuses.ERROR}>
        {meta.error}
      </FieldMessage>
    </div>
  );
};
