import * as React from 'react';
import {
  Button,
  FieldMessage,
  Statuses,
  Icon,
  Modal,
  Loader,
} from '@teamsnap/teamsnap-ui';

// Local imports
import './Groups.scss';
import { ActionContainer } from 'components/shared';
import { GroupCard } from '../GroupCard/GroupCard';
import { Header } from '../Header/Header';
import { useUserSelector } from 'state/user/userSlice';
import {
  useFormCapacityDetailsSelector,
  useFormSelector,
} from 'state/form/formSlice';
import {
  createRegistration,
  updateRegistration,
  useCurrentGroupSelector,
  useCurrentMemberSelector,
  useRegistrationSelector,
} from 'state/registration/registrationSlice';
import { useAppDispatch, useAppNavigate } from 'state/hooks';
import { RegistrantInfo } from '../RegistrantInfo/RegistrantInfo';
import { RegistrationCreateAction } from 'state/registration/types';
import { FieldResults, Registration } from 'core/api';
import { CartButton } from 'components/shared/CartButton';
import { GroupData } from 'pages/Form/GroupsPage';

interface Props {
  groups: GroupData[];
}

export const Groups = ({ groups }: Props) => {
  const [selectedGroup, setSelectedGroup] = React.useState<GroupData | null>(
    null
  );
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const capacityDetails = useFormCapacityDetailsSelector();
  const allGroupsAtCapacity =
    capacityDetails &&
    capacityDetails.length > 0 &&
    capacityDetails.every((group) => group.atCapacity);

  const groupsContainWaitlist =
    groups.length > 0 && groups.some((group) => group.waitlistAllowed);

  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const user = useUserSelector();
  const form = useFormSelector();
  const { organizationName } = form || {};
  const member = useCurrentMemberSelector();
  const currentGroup = useCurrentGroupSelector();
  const registrationData = useRegistrationSelector();

  React.useEffect(() => {
    if (currentGroup) {
      setSelectedGroup(currentGroup);
    }
  }, [currentGroup]);

  const formatCurrency = (amount: number) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount / 100);

  const handleGroupSelection = (group: GroupData) => {
    if (group !== selectedGroup) {
      setHasError(false);
      setSelectedGroup(group);
      dispatch(
        updateRegistration({
          fieldResults: { success: [], failure: [] },
        })
      );
    }
  };

  const goNext = async () => {
    setIsSubmitting(true);
    if (selectedGroup) {
      dispatch(
        updateRegistration({
          currentGroup: selectedGroup,
        })
      );

      // only create registration if there is no registration for the selected member and group
      const currentRegistration = registrationData?.registrations?.find(
        (reg: Registration) => {
          return (
            reg.householdPersonId === member?.id &&
            reg.participantGroupId === selectedGroup?.participantGroupId &&
            reg.formId === form?.id
          );
        }
      ) as Registration;

      const isWaitlisted =
        determineIfAtCapacity(selectedGroup.participantGroupId) &&
        selectedGroup?.waitlistAllowed
          ? true
          : false;

      if (!currentRegistration) {
        const body = {
          householdPersonId: member?.id,
          participantGroupId: selectedGroup?.participantGroupId,
          isWaitlisted,
        } as RegistrationCreateAction;

        await dispatch(createRegistration(body));
        setIsSubmitting(false);
        navigate(`/form/${form?.id}/answerQuestions`);
      } else {
        await dispatch(
          updateRegistration({
            currentRegistration,
          })
        );

        navigate(`/form/${form?.id}/answerQuestions`);
      }
    } else {
      setIsSubmitting(false);
      setHasError(true);
    }
  };

  const determineIfAtCapacity = (participantGroupId: number) => {
    if (Array.isArray(capacityDetails)) {
      const group = capacityDetails.find(
        (group) => group.participantGroupId === participantGroupId
      );

      return group?.atCapacity ?? false;
    }

    return false;
  };

  const renderContent = (groups: GroupData[]) => {
    if (groups && groups.length > 0) {
      return (
        <>
          <div data-testid="group-select" className="u-padEndsLg">
            {groups.map((group: GroupData, index) => (
              <GroupCard
                index={index}
                isDisabled={
                  determineIfAtCapacity(group.participantGroupId) &&
                  !group.waitlistAllowed
                }
                displayPill={determineIfAtCapacity(group.participantGroupId)}
                pillText="Full"
                pillMods={
                  group.waitlistAllowed
                    ? 'u-bg-yellow-1 u-border-yellow-1 u-text-yellow-2'
                    : 'sui-bg-red-2 sui-border-red-2 sui-text-red-6'
                }
                pillHelperText={
                  group.waitlistAllowed ? 'Waitlist available' : ''
                }
                key={`${groups[index].name}-${index}`}
                name={group.name}
                amount={formatCurrency(group.amount)}
                description={group.description}
                onClick={() => handleGroupSelection(group)}
                error={false}
                errorMessage=""
                active={
                  selectedGroup?.participantGroupId === group.participantGroupId
                }
              />
            ))}
          </div>
        </>
      );
    }

    return (
      <div className="u-padEndsLg sui-font-bold">
        There are no groups that the selected participant is currently eligible
        to register for.
      </div>
    );
  };

  return (
    <>
      <ActionContainer
        title="Org Name"
        submitting={isSubmitting}
        header={
          <>
            <Header
              title={organizationName ?? 'Select group'}
              navigation={
                <Button
                  iconPosition="left"
                  mods="back-button sui-m-0 sui-w-auto sui-text-gray-10 t:sui-hidden"
                  icon="arrow-left"
                  type="link"
                  size="large"
                  onClick={() =>
                    navigate(`/form/${form?.id}/selectParticipant`)
                  }
                />
              }
              rightIcon={<CartButton />}
              profileName={`${user?.firstName} ${user?.lastName}`}
            />
            {member?.person && groups && groups.length > 0 && (
              <RegistrantInfo registrant={member.person} />
            )}
          </>
        }
        footer={
          <div className="t:sui-flex t:items-center t:sui-justify-between">
            <Button
              key="add-another-member"
              mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1 sui-hidden t:sui-flex"
              icon="arrow-left"
              iconPosition="left"
              isDisabled={isSubmitting}
              onClick={() => navigate(`/form/${form?.id}/selectParticipant`)}
            >
              Back
            </Button>
            <Button
              testId="next-button"
              color="primary"
              mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
              icon={isSubmitting ? '' : 'arrow-right'}
              iconPosition="right"
              isDisabled={
                isSubmitting ||
                !(groups && groups.length > 0) ||
                (allGroupsAtCapacity && !groupsContainWaitlist)
              }
              onClick={() => goNext()}
            >
              {isSubmitting ? <Loader type="spin" /> : 'Next'}
            </Button>
          </div>
        }
      >
        <>
          <h1 className="sui-heading-md" data-testid="groups-select-header">
            Select a registration option
          </h1>
          <small className="u-padTopSm">
            Choose the option that participant is registering for. Eligibility
            criteria may impact the options available.
          </small>

          {hasError && (
            <FieldMessage mods="sui-caption" status={Statuses.ERROR}>
              Please select a registration option.
            </FieldMessage>
          )}

          {renderContent(groups)}

          <span className="sui-text-gray-7 sui-flex sui-items-center">
            <span>I don't see the desired option</span>

            <Button
              mods="u-borderNone sui-p-0 sui-m-0"
              onClick={() => setOpen(true)}
            >
              <Icon name="help" mods="sui-text-blue" />
            </Button>
          </span>
        </>
      </ActionContainer>

      <span className="modal-wrapper">
        <Modal
          heading=""
          show={open}
          allowOverlayClose={false}
          testId="select-group-help-modal"
        >
          <div className="sui-flex sui-flex-col sui-py-3 sui-px-2 sui-font-size-7">
            <div className="sui-flex sui-flex-row sui-justify-between">
              <div>
                <h2>Help</h2>
              </div>

              <div>
                <Button
                  testId="select-group-help-link"
                  size="large"
                  type="link"
                  onClick={() => setOpen(false)}
                >
                  <Icon name="dismiss" mods="sui-text-gray-8" />
                </Button>
              </div>
            </div>

            <div className="sui-mt-4">
              <p>
                Eligible registration options are displayed based on the
                information entered for the selected participant. If the desired
                option is not listed, please verify:
              </p>
            </div>

            <div className="sui-mt-4">
              <ul className="sui-mx-4">
                <li>
                  <Button
                    testId="select-group-correct-participant-link"
                    size="large"
                    type="link"
                    onClick={() =>
                      navigate(`/form/${form?.id}/selectParticipant`)
                    }
                  >
                    Correct participant
                  </Button>{' '}
                  is selected.
                </li>

                <li className="sui-mt-2">
                  <Button
                    testId="select-group-accurate-participant-link"
                    size="large"
                    type="link"
                    onClick={() =>
                      navigate(`/form/${form?.id}/selectParticipant`, {
                        state: { isConfirmOpen: true, member },
                      })
                    }
                  >
                    Participant's birthdate and gender
                  </Button>{' '}
                  selections are accurate.
                </li>
              </ul>
            </div>

            <div className="sui-mt-4">
              If the desired option is still missing, please contact your
              organization administrator for further assistance.
            </div>
          </div>
        </Modal>
      </span>
    </>
  );
};
