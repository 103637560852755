import * as React from 'react';

import { ProfileImage } from '../ProfileImage/ProfileImage';

interface Props {
  navigation?: React.ReactNode;
  title: string;
  profileImg?: string;
  rightIcon?: React.ReactNode;
  profileName?: string;
  style?: React.CSSProperties;
  textAlign?: string;
  truncateText?: boolean;
  testId?: string;
}

export const Header = ({
  testId = 'action-container-header',
  profileImg = '',
  navigation,
  rightIcon,
  title,
  profileName = '',
  style,
  textAlign = 'center',
  truncateText = true,
}: Props) => {
  return (
    <header data-testid={testId} className="ActionContainer-header" style={style}>
      <div className="sui-flex sui-items-center sui-justify-center sui-px-3">
        <div
          data-testid="action-container-left-icon"
          className={`${textAlign === 'center' ? 'sui-w-1/12 t:sui-w-3/12' : ''} sui-flex-none`}
        >
          {navigation && <>{navigation}</>}
        </div>

        <div
          data-testid="action-container-title"
          className={`sui-heading-sm sui-flex-auto ${truncateText ? 'u-textNoWrap u-textEllipsis' : ''} ${
            textAlign ? 'sui-text-' + textAlign : 'sui-text-center '
          }`}
        >
          {title}
        </div>

        <div className={`${profileName ? 'sui-w-3/12' : ''} sui-flex-none sui-flex sui-items-center sui-justify-end`}>
          {(profileName !== '' || profileImg !== '') && (
            <ProfileImage name={profileName} src={profileImg} size={32} showActions={true} />
          )}
          {rightIcon && (
            <div
              data-testid="action-container-right-icon"
              className="sui-flex sui-items-center sui-justify-center"
            >
              {rightIcon}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};
