import * as Yup from 'yup';

import { FieldType } from 'components/Form/FormField/FormField';
import { FormField } from 'core/api';

const MAX_NAME_LENGTH = 40;

export const FILE_UPLOADING = 'uploading';

export const EMAIL = Yup.string()
  .email('Email format is invalid.')
  .matches(/^\S+@\S+\.\S+$/, { message: 'Email format is invalid.', excludeEmptyString: true })
  .required('Email is required.')
  .max(254, 'Max email length is 254 characters');

export const NON_REQUIRED_EMAIL = Yup.string()
  .email('Email format is invalid.')
  .matches(/^\S+@\S+\.\S+$/, { message: 'Email format is invalid.', excludeEmptyString: true })
  .max(254, 'Max email length is 254 characters');

export const FIRST_NAME = Yup.string()
  .required('First name is required.')
  .max(MAX_NAME_LENGTH, `First name must be at most ${MAX_NAME_LENGTH} characters.`);

export const LAST_NAME = Yup.string()
  .required('Last name is required.')
  .max(MAX_NAME_LENGTH, `Last name must be at most ${MAX_NAME_LENGTH} characters.`);

export const PHONE_NUMBER = Yup.string()
  .required('Phone number is required.')
  .max(10, 'Phone number must be at most 10 characters.');

export const PASSWORD = Yup.string()
  .required('Password is required.')
  .min(8, 'Password must contain at least 8 characters.')
  .max(255, 'Password cannot be more than 255 characters.')
  .notOneOf([Yup.ref('email')], 'Password cannot be same as email.');

export const BIRTH_DATE = Yup.string().required('Birthdate is required.');
export const GENDER = Yup.string().required('Gender is required.');
export const LOGIN_PASSWORD = Yup.string().required('Password is required.');
export const TERMS_AND_PRIVACY = Yup.bool().oneOf([true], 'You must first accept the Terms & Service.');
export const NEWSLETTER = Yup.bool();
export const HOUSEHOLD_ID = Yup.string().required('Household is required.');;

export const getValidation = (field: FormField) => {
  const {
    required,
    formFieldDefinition: { type, label },
  } = field;

  switch (type) {
    case FieldType.LAST_NAME:
    case FieldType.FIRST_NAME: {
      const validation = Yup.string()
        .nullable()
        .max(MAX_NAME_LENGTH, `${label} must be at most ${MAX_NAME_LENGTH} characters.`);

      return required ? validation.required(`${label} is required.`) : validation;
    }
    case FieldType.EMAIL: {
      const validation = Yup.string()
        .nullable()
        .email('Email format is invalid.')
        .matches(/^\S+@\S+\.\S+$/, { message: 'Email format is invalid.', excludeEmptyString: true })
        .max(254, 'Max email length is 254 characters');

      return required ? validation.required(`${label} is required.`) : validation;
    }
    case FieldType.PHONE: {
      const validation = Yup.string()
        .nullable()
        .matches(
          /^(\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4})?$/,
          `${label} must match (xxx) xxx-xxxx | xxx-xxx-xxxx | xxxxxxxxxx`
        );

      return required ? validation.required(`${label} is required.`) : validation;
    }
    case FieldType.FILE: {
      const validation = Yup.string()
        .nullable()
        .test('isFileUploading', 'File is still loading', (value: string | unknown) => value !== FILE_UPLOADING);

      return required ? validation.required(`Please upload ${label} to proceed.`) : validation;
    }
    default: {
      if (!field.required) {
        return field.multiselect ? Yup.array().nullable() : Yup.string().nullable();
      }

      const strippedLabel = label.replace(/[^a-zA-Z0-9 ]/g, '');

      return field.multiselect
        ? Yup.array().required(`${strippedLabel} is required.`)
        : Yup.string().nullable().required(`${strippedLabel} is required.`);
    }
  }
};
