import * as React from 'react';
import { Icon } from '@teamsnap/snap-ui';
import { Button, Modal } from '@teamsnap/teamsnap-ui';

// Local Imports
import './InvalidDiscountWarningModal.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const InvalidDiscountWarningModal: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <React.Fragment>
      {isOpen && (
        <div className="InvalidDiscountWarningModal">
          <Modal heading={''} show={isOpen} closeFn={onClose}>
            <div className="sui-flex sui-flex-col sui-mx-2 t:sui-mx-4 sui-items-center">
              <Icon filled name="warning" size="xl" style={{ color: '#FCD603' }} />

              <div
                data-testid="InvalidDiscountWarningModal--title"
                className="sui-mt-2 sui-font-size-6 sui-text-center"
              >
                Discount is no longer valid.
              </div>

              <p
                data-testid="InvalidDiscountWarningModal--helper-text"
                className="sui-mt-4 sui-font-size-5 sui-text-center"
              >
                Please remove highlighted discount(s) from your cart.
              </p>

              <Button
                testId="InvalidDiscountWarningModal--go-to-cart"
                color="primary"
                onClick={() => onClose()}
                mods="sui-w-full sui-mt-8 sui-mb-2 sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
              >
                Go to cart
              </Button>
            </div>
          </Modal>
        </div>
      )}
    </React.Fragment>
  );
};
