/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateHouseholdPerson } from '../models/CreateHouseholdPerson';
import type { HouseholdAndPerson } from '../models/HouseholdAndPerson';
import type { HouseholdPerson } from '../models/HouseholdPerson';
import type { HouseholdsAndPersons } from '../models/HouseholdsAndPersons';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HouseholdsService {

    /**
     * Returns all households and household persons for the current user
     * @returns HouseholdsAndPersons Success
     * @throws ApiError
     */
    public static getHouseholdsAndPersons(): CancelablePromise<HouseholdsAndPersons> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/households-and-persons',
        });
    }

    /**
     * Creates a household for the current user and creates new household person
     * @param requestBody
     * @returns HouseholdAndPerson Success
     * @throws ApiError
     */
    public static createHouseholdAndPerson(
        requestBody?: CreateHouseholdPerson,
    ): CancelablePromise<HouseholdAndPerson> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/households-and-persons',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Error`,
            },
        });
    }

    /**
     * Deletes an existing household member.
     * @param householdId
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static deleteHouseholdPerson(
        householdId: number,
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/households/{householdId}/household-persons/{id}',
            path: {
                'householdId': householdId,
                'id': id,
            },
        });
    }

    /**
     * Creates a household person for the given household
     * @param householdId
     * @param requestBody
     * @returns HouseholdPerson Success
     * @throws ApiError
     */
    public static createHouseholdPerson(
        householdId: number,
        requestBody?: CreateHouseholdPerson,
    ): CancelablePromise<HouseholdPerson> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/households/{householdId}/household-persons',
            path: {
                'householdId': householdId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Error`,
            },
        });
    }

}
