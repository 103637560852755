/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type HouseholdPerson = {
    id: number;
    householdId: number;
    personId: number;
    person: {
        id: number;
        firstName: string;
        lastName: string;
        gender: HouseholdPerson.gender | null;
        birthdate?: string;
        email?: {
            id: number;
            emailAddress: string;
        };
        phone?: {
            id: number;
            phoneNumber: string;
        };
        uuid: string;
        userId?: number | null;
        age?: number;
    };
    isOrganizer: boolean;
};

export namespace HouseholdPerson {

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }


}

