import React from 'react';
import { Groups } from 'components/Form';
import { useFormSelector } from 'state/form/formSlice';
import { useCurrentMemberSelector, useRegistrationRegistrationsSelector } from 'state/registration/registrationSlice';
import { useAppNavigate } from 'state/hooks';
import { RegistrationUpdate } from 'core/api';
import { FeatureFlagService } from 'frontend-toolkit';
import { FeatureFlagConfig, FeatureFlags } from 'core/feature-flags';
import ENVIRONMENT from 'core/environment';

export type GroupData = {
  participantGroupId: number;
  amount: number;
  description?: string;
  name: string;
  waitlistAllowed: boolean;
};

export const GroupsPage = () => {
  const form = useFormSelector();
  const registrations = useRegistrationRegistrationsSelector();
  const member = useCurrentMemberSelector();
  const navigate = useAppNavigate();

  const { organizationId } = form || {};

  // TODO: Remove Feature Flag once feature is fully released.
  const isParticipantGroupRestrictionEnabled = FeatureFlagService.isFeatureEnabledForOrg(
    FeatureFlagConfig[FeatureFlags.PARTICIPANT_GROUP_RESTRICTION],
    ENVIRONMENT,
    organizationId
  );

  // TODO: Remove Feature Flag once feature is fully released.
  const isParticipantGroupWaitlistEnabled = FeatureFlagService.isFeatureEnabledForOrg(
    FeatureFlagConfig[FeatureFlags.PARTICIPANT_GROUP_WAITLIST],
    ENVIRONMENT,
    organizationId
  );

  React.useEffect(() => {
    if (!member) {
      navigate(`/form/${form?.id}/selectParticipant`);
    }
  }, [member]);

  if (!form) {
    return null;
  }

  const groupData: GroupData[] = [];

  form.participantGroups
    .filter((g) => g.active)
    .reduce((acc: GroupData[], group) => {
      if (isParticipantGroupRestrictionEnabled) {
        if (group?.genderOptions) {
          const genders = group?.genderOptions.split(',').map((gr: string) => gr.toLowerCase());
          const memberGender = member?.person?.gender?.toLowerCase() || '';
          if (!genders.includes(memberGender)) return acc;
        }

        if (group?.fromBirthdateRange) {
          const fromBirthdateRule = new Date(group?.fromBirthdateRange);
          const memberBirthdate = new Date(member?.person?.birthdate || '');
          if (memberBirthdate < fromBirthdateRule) return acc;
        }

        if (group?.toBirthdateRange) {
          const toBirthdateRule = new Date(group?.toBirthdateRange);
          const memberBirthdate = new Date(member?.person?.birthdate || '');
          if (memberBirthdate > toBirthdateRule) return acc;
        }
      }

      const inCartRegistrationsWithMatchingGroup = registrations?.filter(
        (registration) =>
          registration.status === RegistrationUpdate.status.IN_CART && registration.participantGroupId === group?.id
      );

      const groupIsInCart = inCartRegistrationsWithMatchingGroup?.find(
        (registration) => registration.householdPersonId === member?.id
      );

      if (!groupIsInCart && group) {
        acc.push({
          participantGroupId: group.id,
          amount: group.participantFee,
          description: group.description,
          name: group.name,
          waitlistAllowed: isParticipantGroupWaitlistEnabled ? group.waitlistAllowed : false, // TODO: Remove check once feature is fully released.
        });
      }

      return acc;
    }, groupData);

  return <Groups groups={groupData} />;
};
