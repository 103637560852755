import React, { ChangeEvent } from 'react';
import axios, { AxiosError } from 'axios';
import checkImg from '../../../assets/images/check.svg';
import { useAppDispatch } from 'state/hooks';
import { useField } from 'formik';
import { ValidationError } from 'yup';
import { Button, PanelBody, PanelRow, ScreenReader } from '@teamsnap/teamsnap-ui';
import { loadFieldSignupUploadUrl, useCurrentRegistration } from 'state/registration/registrationSlice';
import { YupFile } from 'core/validations/file';
import { SignedUploadUrl } from 'core/api';
import { FILE_UPLOADING } from 'core/validations';
import { useFormSelector } from 'state/form/formSlice';

interface FileProperties {
  error: string | undefined;
  value: string;
  fileName: string;
  isLoading: boolean;
}

export interface Props {
  id: string;
  label: string | React.ReactNode;
  name: string;
  value: string;
}

export const FileUpload = ({ id, label, ...props }: Props) => {
  const [, meta, { setValue, setError, setTouched }] = useField(props);
  const { name, value } = props;
  const { error } = meta;
  const [fileProperties, setFileProperties] = React.useState<FileProperties>({
    fileName: '',
    value: value,
    error: undefined,
    isLoading: false,
  });
  const fileInputRef = React.createRef<HTMLInputElement>();
  const dispatch = useAppDispatch();
  const currentRegistration = useCurrentRegistration();
  const regForm = useFormSelector();
  const isValidFile = fileProperties.fileName || value;
  const timeoutInMilliseconds = 60000;

  React.useEffect(() => {
    setError(fileProperties.error);
    setValue(fileProperties.value, false);
    setTouched(true, false);
  }, [fileProperties]);

  const triggerFileInput = () => {
    fileInputRef?.current?.click();
  };

  const loadFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setFileProperties({ ...fileProperties, error: undefined, value: '', fileName: '' });
      event.target.value = '';
      new YupFile()
        .validateFile()
        .validate(file)
        .then(() => getUploadUrl(file))
        .catch(handleError);
    }
  };

  const handleError = (error: ValidationError | AxiosError | Error) => {
    const timeoutError =
      'File timed out and could not be loaded. Please check network settings or try again with a smaller file.';
    const errorMessage = error.message.includes('timeout') ? timeoutError : error.message;
    setFileProperties({
      ...fileProperties,
      error: errorMessage,
      value: '',
      isLoading: false,
    });
  };

  const getUploadUrl = (file: File) => {
    const fileName = file.name.split('.').pop();

    if (!currentRegistration || !fileName) return;

    setFileProperties({
      ...fileProperties,
      isLoading: true,
      value: FILE_UPLOADING,
      error: undefined,
    });

    dispatch(
      loadFieldSignupUploadUrl({
        registrationId: currentRegistration.id,
        formFieldId: Number(name),
        fileExtension: fileName,
      })
    )
      .unwrap()
      .then(({ data }) => uploadFile(data, file))
      .catch(handleError);
  };

  const uploadFile = (signedUploadUrl: SignedUploadUrl | null, file: File) => {
    if (signedUploadUrl) {
      axios
        .put(signedUploadUrl.url, file, {
          headers: { 'Content-Type': signedUploadUrl.contentType },
          timeout: timeoutInMilliseconds,
        })
        .then(() => {
          setFileProperties({
            value: signedUploadUrl.fileName,
            fileName: file.name,
            isLoading: false,
            error: undefined,
          });
        })
        .catch(handleError);
    }
  };

  return (
    <div id={id} className={`Panel sui-p-1 u-spaceBottomNone ${error && 'sui-border-red-4'}`}>
      <div
        className="content"
        dangerouslySetInnerHTML={{
          __html: regForm?.fields.find((x) => x.id === +id)?.helpText || '',
        }}
      ></div>
      <div className={`Panel u-spaceBottomNone sui-mt-2 ${error && 'sui-border-red-4'}`}>
        <PanelBody>
          <PanelRow mods={'Grid u-flexAuto u-padBottomMd'}>
            {isValidFile && !fileProperties.isLoading ? (
              <div className="Grid-cell u-size1of12 u-spaceTopXs">
                <img alt="checked" src={checkImg} />
              </div>
            ) : (
              fileProperties.isLoading && (
                <div className="Grid-cell u-size1of12 u-spaceTopXs">
                  <img alt="checked" src={checkImg} />
                </div>
              )
            )}
            <div className={`Grid-cell ${isValidFile ? 'u-size9of12' : 'u-size10of12'} u-spaceTopXs`}>
              <label htmlFor={name} className="sui-label">
                {label}
              </label>
              <div id={`label-${name}`} className="u-fontSizeSm u-colorGrey">
                {fileProperties.fileName}
              </div>
            </div>
            <div className={'Grid-cell u-size2of12 u-textRight'}>
              {!fileProperties.isLoading &&
                (isValidFile ? (
                  <Button type={'button'} icon={'edit'} onClick={triggerFileInput}>
                    <ScreenReader>Edit</ScreenReader>
                  </Button>
                ) : (
                  <Button type="link" onClick={triggerFileInput}>
                    Upload
                  </Button>
                ))}
              <input
                id={`upload-${name}`}
                data-testid={`upload-${name}`}
                style={{ display: 'none' }}
                type="file"
                name={name}
                value={undefined}
                accept=".pdf,.jpg,.jpeg,.png"
                ref={fileInputRef}
                onChange={loadFile}
              />
            </div>
          </PanelRow>
        </PanelBody>
      </div>
      <span className="sui-text-gray-40 sui-text-desktop-3">Upload jpg, png, or pdf files.</span>
    </div>
  );
};
