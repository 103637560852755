import * as Sentry from '@sentry/react';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FormsService } from 'core/api';
import { AsyncState } from 'core/types';
import { asyncFulfilledSubReducer, asyncPendingReducer, handleErrors, useAppSelector } from '../hooks';
import { FormState } from './types';
import { RootState } from '../store';

const initialState: AsyncState<FormState> = {
  data: null,
  processing: false,
  error: false,
  errors: [],
};

export const loadForm = createAsyncThunk('form/load', (formId: number) =>
  handleErrors(() => FormsService.getForm(formId))
);

export const loadConfirmationMessage = createAsyncThunk('form/loadConfirmationMessage', (formId: number) =>
  handleErrors(() => FormsService.getConfirmationMessage(formId))
);

export const loadCapacityCounts = createAsyncThunk('form/loadCapacityCounts', (formId: number) =>
  handleErrors(() => FormsService.getCapacityCounts(formId))
);

export const loadDiscountSearchResults = createAsyncThunk(
  'form/couponDetails',
  (data: { formId: number; couponCode: string | undefined }) =>
    handleErrors(() => FormsService.getFormAdjustmentByCouponCode(data.formId, data.couponCode || ''))
);

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadForm.pending, asyncPendingReducer);
    builder.addCase(loadForm.fulfilled, (state, action) => {
      if (action.payload.data?.organizationId) Sentry.setTag('orgId', `${action.payload.data.organizationId}`);
      return asyncFulfilledSubReducer(state, action, 'currentForm');
    });
    builder.addCase(loadConfirmationMessage.pending, asyncPendingReducer);
    builder.addCase(loadConfirmationMessage.fulfilled, (state, action) =>
      asyncFulfilledSubReducer(state, action, 'confirmationMessage')
    );
    builder.addCase(loadCapacityCounts.pending, asyncPendingReducer);
    builder.addCase(loadCapacityCounts.fulfilled, (state, action) =>
      asyncFulfilledSubReducer(state, action, 'capacityDetails')
    );
  },
});

export const useFormStateSelector = () => useAppSelector((state) => state.form);
export const useFormSelector = () => useAppSelector((state) => state.form.data?.currentForm);
export const useFormConfirmationSelector = () => useAppSelector((state) => state.form.data?.confirmationMessage);

export const formCapacityDetailsSelector = (state: RootState) => state.form.data?.capacityDetails ?? [];
export const formDetailsLoadedSelector = (state: RootState) => state.form.data?.couponDetails ?? [];
export const useFormCapacityDetailsSelector = () => useAppSelector(formCapacityDetailsSelector);
export const useFormCouponDetailsSelector = () => useAppSelector(formDetailsLoadedSelector);
export const useFormCapacityDetailsLoadedSelector = () => useAppSelector((state) => !!state.form.data?.capacityDetails);
export const formGroupsWaitlistAllowedSelector = (state: RootState) =>
  state.form.data?.currentForm?.participantGroups?.filter((g) => g.waitlistAllowed) ?? [];

export const useCurrentFormSelector = () => useAppSelector((state) => state.form.data?.currentForm);
export const useGetFormGroupsSelector = () =>
  useAppSelector((state) => state.form.data?.currentForm?.participantGroups);

export const inactiveFormGroupsSelector = (state: RootState) =>
  state.form.data?.currentForm?.participantGroups?.filter((g) => !g.active) ?? [];

export const useFormStateErrorSelector = () => useAppSelector((state) => state.form.error);
export const useFormStateErrorsSelector = () => useAppSelector((state) => state.form.errors);

export default formSlice.reducer;
