/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RegistrationUpdate = {
    status?: RegistrationUpdate.status;
};

export namespace RegistrationUpdate {

    export enum status {
        IN_PROGRESS = 'in_progress',
        SUBMITTED = 'submitted',
        CANCELED = 'canceled',
        IN_CART = 'in_cart',
        DELETED = 'deleted',
        CHECKOUT = 'checkout',
    }


}

