import React from 'react';
import * as Yup from 'yup';
import * as validations from 'core/validations';
import { Form, Formik, useFormikContext } from 'formik';
import { Button, Cell, Grid, Loader, Modal } from '@teamsnap/teamsnap-ui';
import {
  CreateHouseholdPerson,
  Gender,
  Household,
  HouseholdPerson,
  Person,
  UpdatePerson,
} from 'core/api';
import { ValidationError } from 'core/api/models/ValidationError';
import { useAppDispatch, useAppNavigate } from 'state/hooks';
import {
  createHouseholdAndPerson,
  createHouseholdPerson,
  deleteHouseholdPerson,
  editHouseholdPerson,
  useHouseholdPeopleSelector,
} from 'state/households/householdSlice';

import './ManageHouseholdMember.scss';
import { updateRegistration } from 'state/registration/registrationSlice';
import { useFormSelector } from 'state/form/formSlice';
import { ActionContainer } from 'components/shared';
import {
  FormField,
  FormFieldType,
  Header,
  ProfileImage,
} from 'components/Form';

export interface ManageHouseholdMemberProps {
  primaryHouseholdId?: number;
  households?: Household[];
  isOpen: boolean;
  householdMember?: HouseholdPerson;
  setIsOpen: (status: boolean) => void;
  type: 'add' | 'edit' | 'confirm';
  onCancel?: () => void;
  canRemoveHouseholdMember: boolean;
}

export const ManageHouseholdMember = ({
  primaryHouseholdId,
  households,
  isOpen,
  householdMember,
  setIsOpen,
  type,
  onCancel,
  canRemoveHouseholdMember
}: ManageHouseholdMemberProps) => {
  const [isRemoveModalOpen, setIsRemoveModalOpen] =
    React.useState<boolean>(false);
  const [isRemoving, setIsRemoving] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const members = useHouseholdPeopleSelector();
  const canDelete = !householdMember?.isOrganizer && canRemoveHouseholdMember;

  const form = useFormSelector();
  const { organizationName, ngbIntegrations } = form || {};

  const navigate = useAppNavigate();

  const initialValues = {
    firstName: householdMember?.person?.firstName ?? '',
    lastName: householdMember?.person?.lastName ?? '',
    birthdate: householdMember?.person?.birthdate ?? '',
    email: householdMember?.person?.email?.emailAddress ?? '',
    gender: householdMember?.person?.gender ?? ('' as Gender),
    household: householdMember?.householdId ?? primaryHouseholdId,
  };

  const schema = Yup.object({
    firstName: validations.FIRST_NAME,
    lastName: validations.LAST_NAME,
    birthdate: validations.BIRTH_DATE,
    gender: validations.GENDER,
    household: validations.HOUSEHOLD_ID,
    email: validations.NON_REQUIRED_EMAIL,
  });

  const handleModalClose = () => {
    if (onCancel) {
      onCancel();
    }
    setIsRemoveModalOpen(false);
    setIsOpen(false);
  };

  const handleRemoveMember = async () => {
    if (householdMember?.householdId && householdMember?.id) {
      setIsRemoving(true);
      const householdPersonId = (householdMember as unknown as HouseholdPerson)
        .id;
      const deleteData = {
        householdId: householdMember?.householdId,
        householdPersonId,
      };

      await dispatch(deleteHouseholdPerson(deleteData));
      setIsRemoving(false);
      handleModalClose();
    }
  };

  const generateGenderOptions = () => {
    const options = Object.keys(Gender).map((key) => {
      const dropdownOption = {
        label: key[0].toUpperCase() + key.slice(1).toLowerCase(),
        value: key.toLowerCase(),
      };

      return dropdownOption;
    });

    return options;
  };

  const generateHouseholdOptions = () => {
    const options = [
      ...(households?.map((h) => ({ value: `${h.id}`, label: h.name })) || []),
    ];

    // logged in user does not have own household
    if (!primaryHouseholdId) {
      options.push({ value: '0', label: 'Create New Household' });
    }

    return options;
  };

  const removeModalChildren = (
    <div className="shareform-cancel-modal-children-container sui-p-2 sui-pb-0">
      <div className="text-container">
        <p className="sui-body">
          Are you sure you wish to permanently delete this member of your
          household? It cannot be undone.
        </p>
      </div>

      <div className="sui-flex sui-justify-end sui-mb-1 sui-mt-4">
        <Button
          onClick={() => setIsRemoveModalOpen(false)}
          type="link"
          mods="u-colorPrimary sui-font-semibold"
        >
          Cancel
        </Button>

        <Button
          mods="u-colorNegative sui-font-semibold sui-ml-5"
          type="link"
          onClick={handleRemoveMember}
          isDisabled={isRemoving}
          testId="remove-household-member-confirmation"
        >
          {isRemoving ? <Loader type="spin" /> : 'Remove'}
        </Button>
      </div>
    </div>
  );

  const ResetForm = (action: { resetForm: () => void }) => {
    React.useEffect(() => {
      if (!isOpen) {
        action.resetForm();
      }
    }, [isOpen]);

    return null;
  };

  const ProfilePicture = () => {
    const { values } = useFormikContext();

    return (
      <ProfileImage
        name={`${(values as Person)?.firstName ?? ''} ${
          (values as Person)?.lastName ?? ''
        }`}
        size={72}
      />
    );
  };

  const actionText =
    type === 'add' ? 'Add' : type === 'edit' ? 'Save' : 'Confirm Information';

  return (
    <>
      <span className="modal-wrapper">
        <Modal
          heading={''}
          showClose={false}
          show={isOpen}
          allowOverlayClose={false}
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (
              values,
              { setFieldError, setSubmitting, resetForm }
            ) => {
              setSubmitting(true);
              const memberData = {
                body: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  gender:
                    type === 'add'
                      ? (values.gender as CreateHouseholdPerson.gender)
                      : (values.gender as UpdatePerson.gender),
                  birthdate: values.birthdate,
                  email: values.email,
                },
              };

              let error, errors;

              if (type === 'add') {
                if (values.household && +values.household > 0) {
                  const result = await dispatch(
                    createHouseholdPerson({
                      ...memberData,
                      householdId: +values.household,
                    })
                  ).unwrap();
                  error = result.error;
                  errors = result.errors;
                } else {
                  const result = await dispatch(
                    createHouseholdAndPerson(memberData)
                  ).unwrap();
                  error = result.error;
                  errors = result.errors;
                }
              } else {
                const result = await dispatch(
                  editHouseholdPerson({
                    ...memberData,
                    id: (householdMember as HouseholdPerson)?.personId,
                  })
                ).unwrap();
                error = result.error;
                errors = result.errors;

                if (type === 'confirm') {
                  const selectedMember = members?.find(
                    (m) => m?.person?.id === result?.data?.id
                  );
                  dispatch(
                    updateRegistration({
                      currentMember: {
                        ...selectedMember,
                        person: result.data,
                      } as HouseholdPerson,
                    })
                  );
                  if (ngbIntegrations && ngbIntegrations.length > 0) {
                    navigate(`/form/${form?.id}/verifyMembership`);
                  } else {
                    navigate(`/form/${form?.id}/selectGroup`);
                  }
                }
              }

              if (!error) {
                setSubmitting(false);
                setIsOpen(false);
                resetForm();
              } else {
                setSubmitting(false);

                errors.forEach((error) => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const e: ValidationError = error as any;

                  if (e.field === 'email_address') {
                    setFieldError('email', e.message);
                  } else {
                    setFieldError(e.field, e.message);
                  }
                });
              }
            }}
          >
            {({ handleSubmit, resetForm, isSubmitting }) => (
              <ActionContainer
                header={
                  <Header
                    testId="modal-action-container-header"
                    title={organizationName ?? 'Add Household Member'}
                    textAlign="left"
                    navigation={
                      <>
                        {type !== 'confirm' && (
                          <Button
                            iconPosition="left"
                            mods="back-button sui-m-0 sui-w-auto sui-text-gray-10 t:sui-hidden sui-mr-3"
                            icon="dismiss"
                            type="link"
                            onClick={handleModalClose}
                          />
                        )}
                      </>
                    }
                    rightIcon={
                      <>
                        {type !== 'confirm' && (
                          <Button
                            key="save"
                            onClick={handleSubmit}
                            type="link"
                            mods="sui-my-1 t:sui-w-auto sui-h-auto sui-leading-1 sui-font-semibold t:sui-hidden"
                            isDisabled={isSubmitting}
                          >
                            {isSubmitting ? <Loader type="spin" /> : actionText}
                          </Button>
                        )}
                      </>
                    }
                  />
                }
                footer={
                  <div
                    className={`sui-hidden t:sui-flex items-center sui-justify-end ${
                      type === 'confirm' ? 'sui-hidden t:sui-flex' : ''
                    }`}
                  >
                    <Button
                      mods="sui-w-auto sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
                      isDisabled={isSubmitting}
                      onClick={handleModalClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      testId={type === 'add' ? 'add' : 'save'}
                      color="primary"
                      mods="sui-w-auto sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1 sui-ml-2"
                      isDisabled={isSubmitting}
                      onClick={handleSubmit}
                    >
                      {isSubmitting ? <Loader type="spin" /> : actionText}
                    </Button>
                  </div>
                }
              >
                <Form>
                  <ResetForm resetForm={resetForm} />

                  <Grid isWithGutter>
                    <Cell mods="u-spaceSm">
                      <h3>Confirm participant profile information</h3>
                      <small className="u-padTopSm">
                        Please confirm that the information for the selected
                        participant is accurate and complete.
                      </small>
                    </Cell>

                    {type !== 'add' && (
                      <Cell mods="u-flex u-flexCol u-flexAlignItemsCenter u-spaceBottomMd">
                        <ProfilePicture />
                      </Cell>
                    )}

                    {type === 'add' && (
                      <Cell>
                        <FormField
                          type={FormFieldType.SELECT}
                          label="Select a Household"
                          name="household"
                          options={generateHouseholdOptions()}
                          required
                        />
                      </Cell>
                    )}
                    <Cell>
                      <Grid>
                        <Cell mods="u-size1of2 u-padRightXs">
                          <FormField
                            type={FormFieldType.TEXT}
                            label="First Name"
                            name="firstName"
                            placeholder="First name"
                            required
                          />
                        </Cell>

                        <Cell mods="u-size1of2 u-padLeftXs">
                          <FormField
                            type={FormFieldType.TEXT}
                            label="Last Name"
                            name="lastName"
                            placeholder="Last name"
                            required
                          />
                        </Cell>
                      </Grid>
                    </Cell>

                    <Cell>
                      <FormField
                        type={FormFieldType.DATETIME}
                        label="Birthdate"
                        required
                        name="birthdate"
                      />
                    </Cell>

                    <Cell>
                      <FormField
                        type={FormFieldType.SELECT}
                        label="Gender"
                        name="gender"
                        options={generateGenderOptions()}
                        required
                      />
                    </Cell>

                    <Cell>
                      <FormField
                        type={FormFieldType.EMAIL}
                        label="Email"
                        name="email"
                        placeholder="email@email.com"
                      />
                      <p className="sui-caption sui-p-1">
                        This email is used to access team communications and
                        team accounts. Enter a unique email address if the
                        household member wants to log into their own account or
                        receive team emails.
                      </p>
                    </Cell>
                    {type === 'confirm' && (
                      <div className="sui-w-full items-center t:sui-hidden sui-flex-col sui-flex sui-mt-2">
                        <Button
                          testId="confirm"
                          color="primary"
                          mods="sui-w-auto sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
                          isDisabled={isSubmitting}
                          onClick={handleSubmit}
                        >
                          {isSubmitting ? (
                            <Loader type="spin" />
                          ) : (
                            'Confirm Information'
                          )}
                        </Button>
                        <Button
                          mods="sui-w-auto sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
                          isDisabled={isSubmitting}
                          type="link"
                          onClick={handleModalClose}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </Grid>

                  {type === 'edit' && canDelete && (
                    <div className="t:sui-flex t:sui-w-full t:sui-justify-end">
                      <Button
                        testId="remove-from-household"
                        key="remove-from-household"
                        onClick={() => setIsRemoveModalOpen(true)}
                        icon="trash"
                        mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1 sui-flex sui-justify-center sui-mt-3 u-colorNegative"
                      >
                        Remove from household
                      </Button>
                    </div>
                  )}
                </Form>
              </ActionContainer>
            )}
          </Formik>
        </Modal>
      </span>
      <span className="modal-wrapper--nohead">
        <Modal
          data-testid="remove-modal"
          heading={''}
          showClose={false}
          closeFn={() => setIsRemoveModalOpen(false)}
          show={isRemoveModalOpen}
          children={removeModalChildren}
        />
      </span>
    </>
  );
};
