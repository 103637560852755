interface Props {
  name?: string;
  id?: string;
  value: number | null;
  onChange: (n: number | null) => unknown;
  className?: string;
  minAmount?: number;
  maxAmount?: number;
  maxSelectOptions?: number;
  label?: string | JSX.Element;
}

const onlyNumbersRegex = /^\d*$/;

export const NumberInput = ({
  onChange,
  className,
  value,
  minAmount,
  maxAmount,
  maxSelectOptions,
  name,
  id,
  label,
}: Props) => {
  const change = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    e.preventDefault();
    const { value } = e.target;

    if (value === null || value === '' || value === undefined) {
      onChange(null);
    } else if (onlyNumbersRegex.test(value.toString())) {
      onChange(parseInt(value));
    }
  };

  const options: string[] = [];
  minAmount = minAmount || 0;

  if (maxAmount !== undefined && (maxSelectOptions === undefined || maxAmount - minAmount <= maxSelectOptions)) {
    for (let i = minAmount || 0; i <= maxAmount; i++) {
      options.push(i.toString());
    }
  }

  const inputClasses = 'Input sui-h-6 sui-px-1 sui-py-1';
  const selectClasses = 'Input SelectBox-options sui-font-size-5';

  return options.length > 0 ? (
    <div className="SelectBox">
      <label htmlFor={name} className="sui-label">
        {label}
      </label>
      <select
        id={id}
        name={name}
        value={value ? value.toString() : undefined}
        onChange={change}
        className={className ? selectClasses + ' ' + className : selectClasses}
        style={{ height: 48, minWidth: 60 }}
      >
        {options?.map((option) => (
          <option key={option} label={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  ) : (
    <div>
      <label htmlFor={name} className="sui-label">
        {label}
      </label>
      <input
        id={id}
        name={name}
        value={value || ''}
        onChange={change}
        className={className ? inputClasses + ' ' + className : inputClasses}
      />
    </div>
  );
};
