import * as React from 'react';
import { Button, Icon } from '@teamsnap/teamsnap-ui';
import { useAppNavigate } from 'state/hooks';
import { useCurrentFormSelector } from 'state/form/formSlice';
import { useInCartRegistrationsSelector } from 'state/registration/registrationSlice';

export const CartButton = () => {
  const navigate = useAppNavigate();
  const currentForm = useCurrentFormSelector();
  const inCartRegistrations = useInCartRegistrationsSelector();

  return (
    <Button
      onClick={() => navigate(`/form/${currentForm?.id}/cart`)}
      type="link"
      style={{ position: 'relative' }}
      mods="cart-button sui-w-3 sui-my-1 sui-p-0 sui-h-auto sui-leading-1  sui-ml-1 t:sui-ml-2"
    >
      <>
        {inCartRegistrations && inCartRegistrations?.length > 0 && <span style={{ position: 'absolute', top: 0, right: -1 }} className="sui-w-1 sui-h-1 sui-bg-red-5 sui-block sui-rounded"></span>}
        <Icon name="cart" mods="sui-text-black" />
      </>
    </Button>
  );
};
