import { EnvironmentUtilities } from 'frontend-toolkit';
import ENVIRONMENT from './environment';

const envUtils = new EnvironmentUtilities(window.location.host);

export const IS_PROD = ENVIRONMENT.indexOf('prod') !== -1;

export const NGB_INTEGRATION_CODES = {
  usaHockey: 'usa_hockey',
  usaLacrosse: 'usa_lacrosse'
};

export const ORDERS_URL = {
  local: 'http://localhost:3006',
  test: 'http://localhost:3006',
  staging: `https://${envUtils.getStagingHost('order', 'staging.order.teamsnap.com')}`,
  prod: 'https://order.teamsnap.com',
}[ENVIRONMENT];

export const APP_URL = {
  local: 'http://localhost:3000',
  test: 'http://localhost:3000',
  staging: 'https://staging.registration.teamsnap.com',
  prod: 'https://registration.teamsnap.com',
}[ENVIRONMENT];

export const API_URL = {
  local: 'http://localhost:5602',
  test: 'http://localhost:5602',
  staging: 'https://staging-registration-api-prime.teamsnap.com',
  prod: 'https://registration-api.teamsnap.com',
}[ENVIRONMENT];

export const TEAMSNAP_URL = {
  local: 'http://localhost:3001',
  test: 'http://localhost:3001',
  staging: 'https://pod-sports-org.teamsnap.com',
  prod: 'https://go.teamsnap.com',
}[ENVIRONMENT];

export const STRIPE_CLIENT_KEY = {
  local: 'pk_test_51Dq5BXH3v9ZxQViIRIr5T0mX3fZxXXYszWlkHrurMnaCQq9UWoXw5jaX6OK7QkG9pALgvQ7zL8zNYXQRiVJKalAn00GyEUjvGu',
  staging:
    'pk_test_51Dq5BXH3v9ZxQViIRIr5T0mX3fZxXXYszWlkHrurMnaCQq9UWoXw5jaX6OK7QkG9pALgvQ7zL8zNYXQRiVJKalAn00GyEUjvGu',
  prod: 'pk_live_VOrEglkce2zbFN3dJ6aEmZ1c',
}[ENVIRONMENT];

export const SENTRY_URL = 'https://2ab07085e0054043970872962e9f1f8c@o126035.ingest.sentry.io/4504120004444160';

export const FIREBASE_API_KEY = {
  local: 'AIzaSyBX7rPA7HT4J2VaWv2EjqRtnX7rxQpchKw',
  staging: 'AIzaSyBX7rPA7HT4J2VaWv2EjqRtnX7rxQpchKw',
  prod: 'AIzaSyDY6y23k8gdOoyfdk_RBaGlBaUam5vuPEk',
}[ENVIRONMENT];

export const FIREBASE_AUTH_DOMAIN = {
  local: 'live-dev-1-1.firebaseapp.com',
  staging: 'live-dev-1-1.firebaseapp.com',
  prod: 'live-prod-1-1.firebaseapp.com',
}[ENVIRONMENT];

export const FIREBASE_DB_URL = {
  local: 'https://live-dev-1-1.firebaseio.com',
  staging: 'https://live-dev-1-1.firebaseio.com',
  prod: 'https://live-prod-1-1.firebaseio.com',
}[ENVIRONMENT];

export const FIREBASE_PROJECT_ID = {
  local: 'live-dev-1-1',
  staging: 'live-dev-1-1',
  prod: 'live-prod-1-1',
}[ENVIRONMENT];

export const FIREBASE_STORAGE_BUCKET = {
  local: 'live-dev-1-1.appspot.com',
  staging: 'live-dev-1-1.appspot.com',
  prod: 'live-prod-1-1.appspot.com',
}[ENVIRONMENT];

export const FIREBASE_MESSAGING_SENDER_ID = {
  local: '979924482870',
  staging: '979924482870',
  prod: '81859586434',
}[ENVIRONMENT];

export const FIREBASE_APP_ID = {
  local: '1:979924482870:web:bda17f9055d1b7784a1197',
  staging: '1:979924482870:web:bda17f9055d1b7784a1197',
  prod: '1:81859586434:web:3d0de5fdf7a1e37325879b',
}[ENVIRONMENT];

export const FIREBASE_MEASURE_ID = {
  local: 'G-N9QE0KDK7P',
  staging: 'G-N9QE0KDK7P',
  prod: 'G-15GWKV6WS0',
}[ENVIRONMENT];
