import * as React from 'react';

import { Button, Modal } from '@teamsnap/teamsnap-ui';
import { Icon } from '@teamsnap/snap-ui';
import { useFormCapacityDetailsSelector, useGetFormGroupsSelector } from 'state/form/formSlice';

import './CartWarningModal.scss';
import { ParticipantGroup, Registration } from 'core/api';

interface Props {
  registrationsAtCapacity: {
    atCapacityWithoutWaitlist: Registration[];
    atCapacityWithWaitlistAndModalInfoNeeded: Registration[];
  };
  registrationsWithInactiveGroup: Registration[];
  isOpen: boolean;
  onClose: () => void;
}

export const CartWarningModal = ({
  registrationsAtCapacity,
  registrationsWithInactiveGroup,
  isOpen,
  onClose,
}: Props) => {
  const currentFormGroups = useGetFormGroupsSelector();
  const capacityDetails = useFormCapacityDetailsSelector();
  const groupsAtMaxCapacityWithNamesWithoutWaitlist: ParticipantGroup[] = currentFormGroups
    ? currentFormGroups.filter((group) => {
        return registrationsAtCapacity.atCapacityWithoutWaitlist.find((g) => g.participantGroupId === group.id);
      })
    : [];

  const groupsAtMaxCapacityWithNamesWithWaitlist: ParticipantGroup[] = currentFormGroups
    ? currentFormGroups.filter((group) => {
        return registrationsAtCapacity.atCapacityWithWaitlistAndModalInfoNeeded.find(
          (g) => g.participantGroupId === group.id
        );
      })
    : [];

  const hasMaxCapacityGroups =
    groupsAtMaxCapacityWithNamesWithoutWaitlist.length > 0 || groupsAtMaxCapacityWithNamesWithWaitlist.length > 0;

  const inactiveGroupsWithRegistrations = currentFormGroups
    ? currentFormGroups.filter((group) => {
        return registrationsWithInactiveGroup.some((r) => r.participantGroupId === group.id);
      })
    : [];
  const hasInactiveGroups = inactiveGroupsWithRegistrations.length > 0;

  return (
    <>
      {isOpen && (
        <div className="CartWarningModal">
          <Modal heading={''} show={isOpen} closeFn={onClose}>
            <div className="sui-flex sui-flex-col sui-mx-2 t:sui-mx-4 sui-items-center">
              <Icon filled name="warning" size="xl" style={{ color: '#FCD603' }} />

              {hasMaxCapacityGroups && (
                <React.Fragment>
                  <div className="sui-mt-2 sui-font-size-6 sui-text-center">Capacity reached</div>

                  <div className="sui-mt-6 sui-font-size-5 sui-text-center">
                    {groupsAtMaxCapacityWithNamesWithoutWaitlist.map((group) => {
                      const capacityDetail = capacityDetails.find((c) => c.participantGroupId === group.id);
                      const remainingCount = capacityDetail?.remainingCapacity ?? 0;
                      const registerCount = registrationsAtCapacity.atCapacityWithoutWaitlist.filter(
                        (r) => r.participantGroupId === group.id
                      ).length;
                      const removeCount = registerCount - remainingCount;

                      return (
                        <p className="sui-mb-2" key={`withoutWaitlist-${group.id}`}>
                          <strong>{group.name}</strong> has {remainingCount + ' ' || 'no more '}
                          open spot(s) remaining. Please remove {removeCount} {group.name} selection(s) from your cart.
                        </p>
                      );
                    })}

                    {groupsAtMaxCapacityWithNamesWithWaitlist.map((group) => {
                      const capacityDetail = capacityDetails.find((c) => c.participantGroupId === group.id);
                      const remainingCount = capacityDetail?.remainingCapacity ?? 0;
                      const registerCount = registrationsAtCapacity.atCapacityWithWaitlistAndModalInfoNeeded.filter(
                        (r) => r.participantGroupId === group.id
                      ).length;

                      const removeCount = registerCount - remainingCount;

                      return (
                        <React.Fragment key={`withWaitlist-${group.id}`}>
                          {' '}
                          <p className="sui-mb-2" key={group.id}>
                            <strong>{group.name}</strong> has {remainingCount + ' ' || 'no more '}
                            open spot(s) remaining. Please remove {removeCount} <strong>{group.name}</strong>{' '}
                            selection(s) from your cart or join a waitlist.
                          </p>{' '}
                          <p>
                            Amount due today for <strong>{group.name}</strong> will be $0 if joining a waitlist.
                          </p>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </React.Fragment>
              )}

              {hasInactiveGroups && (
                <React.Fragment>
                  <div className="sui-mt-2 sui-font-size-6 sui-text-center">
                    Registration option(s) no longer available
                  </div>

                  <div className="sui-mt-6 sui-font-size-5 sui-text-center">
                    {inactiveGroupsWithRegistrations.map((group) => (
                      <p className="sui-mb-2" key={`withoutWaitlist-${group.id}`}>
                        <strong>{group.name}</strong> is no longer available.
                        <br /> Please remove all <strong>{group.name}</strong> selection(s) from your cart.
                      </p>
                    ))}
                  </div>
                </React.Fragment>
              )}

              <Button
                color="primary"
                onClick={() => onClose()}
                mods="sui-w-full sui-mt-8 sui-mb-2 sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
              >
                Go to cart
              </Button>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};
