import * as React from 'react';

import './Accordion.scss';

type Props = {
  title: string;
  children: JSX.Element;
  openOverride?: boolean;
  hasDivider?: boolean;
};

export const Accordion = ({ title, children, openOverride, hasDivider = true }: Props) => {
  const [isOpen, setIsOpen] = React.useState(true);

  React.useEffect(() => {
    if (openOverride === true) {
      setIsOpen(true);
    }
  }, [openOverride]);

  return (
    <div className={`accordion sui-bg-white sui-mb-1 ${isOpen ? 'accordion--open' : ''}`}>
      <button
        className="accordion-trigger sui-p-2 sui-flex sui-items-center"
        onClick={() => {
          if (!openOverride) {
            setIsOpen(!isOpen);
          }
        }}
        type="button"
      >
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.8751 1.00002L5.9951 4.88002L2.1151 1.00002C1.7251 0.61002 1.0951 0.61002 0.705098 1.00002C0.315098 1.39002 0.315098 2.02002 0.705098 2.41002L5.2951 7.00002C5.6851 7.39002 6.3151 7.39002 6.7051 7.00002L11.2951 2.41002C11.6851 2.02002 11.6851 1.39002 11.2951 1.00002C10.9051 0.62002 10.2651 0.61002 9.8751 1.00002Z"
            fill="#98A4AE"
          />
        </svg>
        <span className="sui-heading-sm sui-ml-2" data-testid="accordion-title">
          {title}
        </span>
      </button>
      <div className="accordion-content">
        <div
          className={`sui-border-0 ${
            hasDivider ? 'sui-border-t sui-border-solid sui-border-gray-5' : null
          } sui-px-1 sui-pb-2`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
