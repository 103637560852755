/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum signature_type {
    CHECKBOX_ACCEPT = 'checkbox_accept',
    INITIALS = 'initials',
    FULL_NAME = 'full_name',
    E_SIGNATURE = 'e_signature',
}
