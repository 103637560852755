// Local imports
import { FormAdjustment } from 'core/api';
import { AdjustmentValue } from './AdjustmentValue';

interface Props {
  fieldValue: string;
  adjustments: FormAdjustment[];
}

export const AdjustmentValueCollector = ({ adjustments, fieldValue }: Props) => {
  const adjustment = adjustments.find((a) => a?.answerToMatch?.trim() === fieldValue?.trim());

  return adjustment ? <AdjustmentValue adjustment={adjustment} /> : null;
};
