import { useField } from 'formik';
import { useEffect } from 'react';

// Local imports
import { FormAdjustment } from 'core/api';
import { NumberInput } from './NumberInput';
import { FieldMessage, Statuses } from '@teamsnap/teamsnap-ui';

interface Props {
  adjustment: FormAdjustment;
}

export const AdjustmentQuantity = ({ adjustment }: Props) => {
  const [field, meta] = useField({
    name: adjustment.id + '-quantity',
    validate: (v) => {
      if (isNaN(v)) return 'Quantity is required';
      const quantity = parseInt(v);
      if (quantity < 1) return 'Must be at least 1';
      if (quantity > adjustment.maxQuantity) return `Must be at most ${adjustment.maxQuantity}`;
    },
  });

  useEffect(() => {
    if (!field.value || field.value === '') {
      field.onChange({ target: { name: field.name, value: '1' } });
    }
  }, []);

  const onChange = (n: number | null) => field.onChange({ target: { name: field.name, value: n?.toString() } });

  return (
    <div className="sui-ml-2">
      <NumberInput
        label={
          <span>
            Quantity<span className="sui-text-red-4">*</span>
          </span>
        }
        id={field.name}
        name={field.name}
        value={field.value}
        minAmount={1}
        maxAmount={adjustment.maxQuantity}
        maxSelectOptions={9}
        onChange={onChange}
      />
      {meta.error && (
        <FieldMessage mods="sui-caption" status={Statuses.ERROR}>
          {meta.error}
        </FieldMessage>
      )}
    </div>
  );
};
