import { NotFound } from 'pages/NotFound';
import { SignWaivers } from 'components/Form/SignWaivers/SignWaivers';
import * as React from 'react';
import { useAppDispatch } from 'state/hooks';
import { useFormSelector } from 'state/form/formSlice';
import { useRegistrationSelector, loadWaiverResults } from 'state/registration/registrationSlice';
import { Registration, RegistrationUpdate } from 'core/api';

export const WaiverPage = () => {
  const form = useFormSelector();
  const dispatch = useAppDispatch();
  const registrationData = useRegistrationSelector();

  React.useEffect(() => {
    const registrationsForForm = registrationData?.registrations?.filter(
      (reg: Registration) => reg.formId === form?.id && reg.status === RegistrationUpdate.status.IN_CART
    );
    registrationsForForm?.forEach((r: Registration) => {
      dispatch(loadWaiverResults(r.id));
    });
  }, [registrationData?.registrations]);

  if (!form) {
    return null;
  }

  if (form?.waivers.length === 0) {
    if (form) {
      window.location.href = `/form/${form.id}/cart`;
    } else {
      return <NotFound />;
    }
  }

  return (
    <SignWaivers registrationWaivers={registrationData?.waiverResults} formWaivers={form?.waivers} formId={form?.id} />
  );
};
