/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePerson } from '../models/CreatePerson';
import type { Person } from '../models/Person';
import type { UpdatePerson } from '../models/UpdatePerson';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PeopleService {

    /**
     * Creates a person for the logged in user
     * @param requestBody
     * @returns Person Success
     * @throws ApiError
     */
    public static createForUser(
        requestBody?: CreatePerson,
    ): CancelablePromise<Person> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/people/create-for-user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Updates a person by id
     * @param id
     * @param requestBody
     * @returns Person Success
     * @throws ApiError
     */
    public static updatePerson(
        id: number,
        requestBody?: UpdatePerson,
    ): CancelablePromise<Person> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/people/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
