/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NgbMemberResponse } from '../models/NgbMemberResponse';
import type { ValidateMembershipRequest } from '../models/ValidateMembershipRequest';
import type { ValidateMembershipResponse } from '../models/ValidateMembershipResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NgbIntegrationsService {

    /**
     * Validates membership for a specific NGB integration
     * @param ngbIntegrationId
     * @param requestBody
     * @returns ValidateMembershipResponse Success
     * @throws ApiError
     */
    public static validateMembership(
        ngbIntegrationId: number,
        requestBody?: ValidateMembershipRequest,
    ): CancelablePromise<ValidateMembershipResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ngb-integrations/{ngbIntegrationId}/validate-membership',
            path: {
                'ngbIntegrationId': ngbIntegrationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Gets ngb member for a specific NGB integration and a specific person uuid
     * @param ngbIntegrationId
     * @param personUuid
     * @returns NgbMemberResponse Success
     * @throws ApiError
     */
    public static getNgbMember(
        ngbIntegrationId: number,
        personUuid?: string,
    ): CancelablePromise<NgbMemberResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ngb-integrations/{ngbIntegrationId}/get-ngb-member',
            path: {
                'ngbIntegrationId': ngbIntegrationId,
            },
            query: {
                'personUuid': personUuid,
            },
        });
    }

}
