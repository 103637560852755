import { PopupAction } from '@teamsnap/teamsnap-ui';

// Local Imports
import { logoutUser } from 'state/user/userSlice';
import { useAppDispatch } from 'state/hooks';

interface Props {
  altText?: string;
  name: string;
  src?: string;
  size?: number;
  showActions?: boolean;
}

export const ProfileImage = ({ altText, name, src, size = 48, showActions = false }: Props) => {
  const dispatch = useAppDispatch();

  const renderInitials = (text: string) => {
    // Null name.
    if (text === ' ') return;

    const chunkedText = text.split(' ');

    return chunkedText.length > 1
      ? (chunkedText[0][0]?.toUpperCase() ?? '') + (chunkedText.slice(-1)[0][0]?.toUpperCase() ?? '')
      : chunkedText[0][0]?.toUpperCase() ?? '';
  };

  const styles = {
    height: size,
    width: size,
    borderRadius: size / 2,
  };

  const handleLogOut = async () => {
    dispatch(logoutUser())
  };

  return (
    <div className="u-flex" data-testid="profile" style={styles}>
      {showActions ? (
        <PopupAction
          text={src ? <img data-testid="profile-image" src={src} alt={altText ?? ''} /> : renderInitials(name)}
          actions={[
            {
              text: 'Log out',
              callback: handleLogOut,
            },
          ]}
          direction={['down', 'rightHang']}
          popupStyle={{ width: '150px' }}
          showOnHover={false}
          buttonStyle={styles}
        />
      ) : src ? (
        <img
          className="u-border u-borderColorPrimary"
          data-testid="profile-image"
          style={styles}
          src={src}
          alt={altText ?? ''}
        />
      ) : (
        <div
          className="u-flex u-flexAlignItemsCenter u-flexJustifyCenter u-border u-borderColorDefault"
          data-testid="profile-image-text"
          style={styles}
        >
          {renderInitials(name)}
        </div>
      )}
    </div>
  );
};
