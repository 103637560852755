import { Avatar, Card, IconButton } from '@teamsnap/snap-ui';
import { HouseholdPerson, Registration } from 'core/api';
import { getUSDFormat } from 'core/utilities';

type HouseholdMemberCardProps = {
  member: HouseholdPerson;
  memberRegistrations: Registration[];
  selected: boolean;
  onClick?: () => void;
  onClickEdit: () => void;
  iconDisabled: boolean;
};

export default function HouseholdMemberCard(props: HouseholdMemberCardProps) {
  const {
    member,
    memberRegistrations = [],
    selected,
    onClick,
    onClickEdit,
    iconDisabled,
  } = props;

  const handleOnClickEdit = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    onClickEdit();
  };

  return (
    <Card
      className="sui-px-3 sui-py-2"
      selected={selected}
      onClick={onClick}
      data-testid="member-card"
    >
      <header className="sui-flex sui-items-center sui-gap-2">
        <Avatar
          type="initials"
          initials={`${member.person?.firstName?.charAt(0) ?? ''}${
            member.person?.lastName?.charAt(0) ?? ''
          }`}
        />
        <div className="sui-flex-1">
          <p
            className="sui-body"
            data-testid="member-card-main-label"
          >{`${member.person?.firstName} ${member.person?.lastName}`}</p>
          <small className="sui-caption">
            {member.isOrganizer
              ? 'Household Organizer'
              : getMemberAgeLabel(member.person?.age)}
          </small>
        </div>
        <IconButton
          disabled={iconDisabled}
          icon="edit"
          onClick={handleOnClickEdit}
          data-testid="member-card-edit-button"
        />
      </header>
      {memberRegistrations.length > 0 && (
        <div className="sui-mt-2">
          <h4 className="title sui-font-normal sui-body sui-w-full sui-text-center sui-text-gray-8 sui-relative sui-mb-1">
            <span
              className="sui-border-b sui-border-solid sui-border-gray-4 sui-absolute"
              style={{ left: 0, right: 0, top: 13 }}
            />
            <span className="sui-bg-white sui-relative">In Cart</span>
          </h4>
          {memberRegistrations.map((registration) => (
            <div
              key={`member-registration-${member.id}-${registration.id}`}
              className="sui-flex sui-justify-between sui-mb-0.5"
            >
              <p className="sui-body sui-font-normal sui-text-gray-8">
                {registration.lineItems.find((l) => l.isRegistrationFee)?.name}
              </p>
              <p className="sui-body sui-font-normal sui-text-gray-8">
                {getUSDFormat(
                  registration.lineItems.reduce(
                    (total, item) => total + item.amount,
                    0
                  ) / 100
                )}
              </p>
            </div>
          ))}
        </div>
      )}
    </Card>
  );
}

function getMemberAgeLabel(age: number | undefined): string {
  if (typeof age !== 'number') return 'Age Unknown';
  if (age === 0) return 'Age <1';
  if (age >= 18) return 'Adult';
  return `Age ${age}`;
}
