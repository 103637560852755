import * as React from 'react';
import { Icon, Tag } from '@teamsnap/teamsnap-ui';
import { DateFormat, DateService } from 'frontend-toolkit';
import { useUserSelector } from 'state/user/userSlice';
import { FeatureFlagService } from 'frontend-toolkit';
import { FeatureFlagConfig, FeatureFlags } from 'core/feature-flags';
import ENVIRONMENT from 'core/environment';

// Local imports
import '../Groups/Groups.scss';
import { ActionContainer } from 'components/shared';
import { useAppNavigate } from 'state/hooks';
import { Header } from '../Header/Header';
import logoImage from '../../../assets/images/logo.svg';
import { CartButton } from 'components/shared/CartButton';
import { useFormCapacityDetailsSelector } from 'state/form/formSlice';

const IconSize = 20;

type ParticipantGroup = {
  id: number;
  participantFee: number;
  name: string;
  waitlistAllowed: boolean;
};

type Season = {
  name: string;
  startDate?: string;
  endDate?: string;
};

type Registration = {
  id: number;
  name: string;
  participantGroups: ParticipantGroup[];
  location?: string;
  startDate?: string;
  endDate?: string;
  season?: Season;
  status: string;
};

interface Props {
  orgName: string;
  registration: Registration;
  orgId: number; // TODO: Remove this once we remove waitlist feature flag.
}

export const Overview = ({
  orgName,
  orgId,
  registration: { name, participantGroups, location, startDate, endDate, id, season, status },
}: Props) => {
  const [submitting, setIsSubmitting] = React.useState<boolean>(false);
  const capacityDetails = useFormCapacityDetailsSelector();
  const allGroupsAtCapacity =
    capacityDetails && capacityDetails.length > 0 && capacityDetails.every((group) => group.atCapacity);

  // TODO: Remove Feature Flag once feature is fully released.
  const isParticipantGroupWaitlistEnabled = FeatureFlagService.isFeatureEnabledForOrg(
    FeatureFlagConfig[FeatureFlags.PARTICIPANT_GROUP_WAITLIST],
    ENVIRONMENT,
    orgId
  );

  const groupHasWaitlist =
    isParticipantGroupWaitlistEnabled &&
    participantGroups &&
    participantGroups.length > 0 &&
    participantGroups.some((group) => group.waitlistAllowed);

  // If the registration is closed, prevent any updates
  React.useEffect(() => {
    if (status === 'closed') {
      setIsSubmitting(true);
    }
  }, []);

  const navigate = useAppNavigate();
  const user = useUserSelector();

  const renderDates = (start?: string, end?: string) => {
    let currStartDate = '';
    let currEndDate = '';

    if (start) {
      currStartDate = DateService.dateToString(new Date(start), DateFormat.SHORT_DATE);
    }

    if (end) {
      currEndDate = DateService.dateToString(new Date(end), DateFormat.SHORT_DATE);
    }

    // If we have start date and no end date
    if (currStartDate && !currEndDate) return `${currStartDate}`;

    // If we have end date and no start date
    if (currEndDate && !currStartDate) return `Ends ${currEndDate}`;

    // If we have both end date and start date but the year is different
    if (currStartDate.split(',')[1] === currEndDate.split(',')[1]) {
      return `${currStartDate.split(',')[0]} \u2013 ${currEndDate}`;
    }

    // If we have both start and end date
    return `${currStartDate} - ${currEndDate}`;
  };

  const renderRegistrationOpenStatus = (status: string, start?: string, end?: string) => {
    if (!startDate && !endDate) {
      return 'Closed';
    }

    if (start && end) {
      return renderDates(start, end);
    }

    return status.charAt(0).toLocaleUpperCase() + status.substring(1, status.length);
  };

  const handleSubmit = () => {
    setIsSubmitting(false);
    navigate(`/form/${id}/selectParticipant`);
  };

  return (
    <>
      <ActionContainer
        actionDisabled={allGroupsAtCapacity && !groupHasWaitlist}
        action={handleSubmit}
        actionLabel={allGroupsAtCapacity && groupHasWaitlist ? 'Join waitlist' : 'Register'}
        title={orgName}
        submitting={submitting}
        header={
          <Header
            title={orgName}
            testId="action-container-header"
            rightIcon={<CartButton />}
            profileName={`${user?.firstName} ${user?.lastName}`}
          />
        }
      >
        <div>
          <div className="sui-flex sui-justify-center sui-mb-3">
            <img className="logo-image" src={logoImage} alt="TeamSnap" />
          </div>

          <h1 className="sui-heading-md" data-testid="registration-title">{name}</h1>

          <h2 className="sui-heading-md sui-mt-4 sui-mb-2" data-testid="registration-details-title">Registration Details</h2>

          {location && (
            <div className="sui-body sui-flex sui-items-center sui-justify-between t:sui-w-12/12 sui-my-1">
              <span>
                <Icon
                  className="sui-text-gray-8 sui-mr-0.5"
                  style={{ width: IconSize, height: IconSize }}
                  name="location"
                />{' '}
                Season
              </span>
              <span className="">{location}</span>
            </div>
          )}

          {/* Do not display dates if we do not have any */}
          {!(!season?.startDate && !season?.endDate) && (
            <div className="sui-body sui-flex sui-items-center sui-justify-between t:sui-w-12/12 sui-my-1">
              <span>
                <Icon
                  mods="sui-text-gray-8 sui-mr-0.5"
                  style={{ width: IconSize, height: IconSize }}
                  name="calendar-today"
                />{' '}
                Season
              </span>
              <span className="">{renderDates(season?.startDate, season?.endDate)}</span>
            </div>
          )}

          <div className="sui-body sui-flex sui-items-center sui-justify-between t:sui-w-12/12 sui-my-1">
            <span>
              <Icon mods="sui-text-gray-8 sui-mr-0.5" style={{ width: IconSize, height: IconSize }} name="stopwatch" />{' '}
              Registration
            </span>
            <span className="">{renderRegistrationOpenStatus(status, startDate, endDate)}</span>
          </div>

          {allGroupsAtCapacity && !groupHasWaitlist && (
            <div className="sui-body sui-flex sui-items-center t:sui-w-12/12 sui-my-3 sui-justify-center">
              <Tag mods="sui-bg-red-2 sui-border-red-2 sui-text-red-6" text="Full" testId="registration-full-tag" />

              <p className="sui-ml-1 sui-text-gray-6" data-testid="registration-full-message">Registration is full. Please contact your organization.</p>
            </div>
          )}

          {allGroupsAtCapacity && groupHasWaitlist && (
            <div className="sui-body sui-flex sui-items-center t:sui-w-12/12 sui-my-3 sui-justify-center">
              <Tag mods="u-bg-yellow-1 u-border-yellow-1 u-text-yellow-2" text="Full" testId="registration-full-tag" />

              <p className="sui-ml-1 sui-text-gray-6">Capacity reached. Waitlist available.</p>
            </div>
          )}
        </div>
      </ActionContainer>
    </>
  );
};
