/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Comparison {
    EQ = 'eq',
    GT = 'gt',
    LT = 'lt',
    LTE = 'lte',
    GTE = 'gte',
    NEQ = 'neq',
    CONTAINS = 'contains',
    NOT_CONTAINS = 'not_contains',
}
