import * as Yup from 'yup';

export class YupFile {
  readonly maxFileSize = 40920; // 40MB in KB
  readonly allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];

  public validateFile() {
    return Yup.mixed()
      .test(
        'fileSize',
        `Please upload a file smaller than ${Math.round(this.maxFileSize / 1024)}MB`,
        (file) => (file instanceof File ? file.size / 1024 <= this.maxFileSize : true)
      )
      .test('fileType', 'Please upload a JPG, PNG, or PDF only', (file) =>
        file instanceof File ? this.allowedFileTypes.some((t) => file.type?.toLowerCase() === t) : true
      );
  }
}
