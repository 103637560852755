import { Tag } from '@teamsnap/teamsnap-ui';
import { PaddedContentArea } from '../../shared/PaddedContentArea/PaddedContentArea';

import './GroupCard.scss';

export interface Props {
  index?: number;
  isDisabled?: boolean;
  displayPill?: boolean;
  pillText?: string;
  pillMods?: string;
  pillHelperText?: string;
  active?: boolean;
  amount: string;
  description?: string;
  error?: boolean;
  errorMessage?: string;
  name: string;
  onClick?: () => void;
}

export const GroupCard = ({
  index,
  isDisabled,
  displayPill,
  pillMods,
  pillText,
  pillHelperText,
  active,
  amount,
  description,
  error,
  errorMessage,
  name,
  onClick,
}: Props) => {
  const containerClasses = [
    onClick ? 'GroupCard' : '',
    'u-flexCol',
    active ? 'u-bgPrimary1 u-borderColorPrimary' : '',
    error ? 'u-borderColorNegative' : '',
    isDisabled ? 'u-cursor-none sui-bg-gray-3' : '',
  ]
    .join(' ')
    .replace(/\s+/g, ' ');

  return (
    <PaddedContentArea
      rounded
      classes={containerClasses}
      onClick={!isDisabled ? onClick : () => {}}
      error={error}
      errorMessage={errorMessage}
    >
      <div data-testid={`group-card-${index}`} className="sui-flex sui-items-center sui-justify-between">
        <div>
          {displayPill && (
            <span data-testid="capacity-label" className="sui-body sui-flex sui-mb-2">
              <Tag mods={pillMods ?? ''} text={pillText ?? ''} />

              {pillHelperText && <p className="sui-ml-1">{pillHelperText}</p>}
            </span>
          )}

          <span className="sui-body">{name}</span>

          {description && <span className="sui-body sui-block sui-text-gray-8 sui-p-0.5">{description}</span>}
        </div>
        <div className="sui-body">{amount}</div>
      </div>
    </PaddedContentArea>
  );
};
