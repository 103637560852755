import { Icon } from '@teamsnap/snap-ui';

import { DiscountType } from './DiscountsCartSection';

type DiscountItemProps = {
  discount: DiscountType;
  onDelete(discount: string): void;
};

type InvalidDiscountItemProps = {
  discount: string;
  onDelete(discount: string): void;
};

export const DiscountItem = ({ discount, onDelete }: DiscountItemProps) => {
  const formatDiscount = (discount: number) => {
    if (Number.isNaN(discount) || discount === null) return;

    const dollarAmount = discount / 100;
    const isNegative = Math.sign(dollarAmount);
    const stringAmount = Math.abs(dollarAmount);

    return `${isNegative < 0 ? '-' : ''}$${stringAmount.toFixed(2)}`;
  };

  return (
    <div className="grid sui-flex sui-align-items sui-justify-between sui-pt-1 sui-mx-1 sui-font-size-5 sui-text-gray-40">
      <div data-testid={`discount-name-${discount.name}`} className="sui-flex sui-basis-8/12 d:sui-basis-9/12">
        {discount.name}
      </div>

      <div
        data-testid={`discount-amount-${discount.name}`}
        className="sui-flex sui-basis-3/12 d:sui-basis-2/12 sui-justify-end"
      >
        {formatDiscount(discount.amount)}
      </div>

      <div className="sui-text-end sui-basis-1/12">
        <Icon
          data-testid={`discount-delete-${discount.name}`}
          name="delete"
          style={{
            color: discount.isAutomaticDiscount ? 'gray' : '#e8301c',
            cursor: discount.isAutomaticDiscount ? null : 'pointer',
          }}
          onClick={() => {
            if (!discount.isAutomaticDiscount) {
              onDelete(discount.name);
            }
          }}
        />
      </div>
    </div>
  );
};

export const InvalidDiscountItem = ({ discount, onDelete }: InvalidDiscountItemProps) => {
  return (
    <div className="grid sui-flex sui-align-items sui-justify-between sui-pt-1 sui-mx-1 sui-font-size-5 sui-text-gray-40">
      <div data-testid={`discount-name-${discount}`} className="sui-flex sui-basis-9/12 sui-text-red-40">
        {discount} (Invalid)
      </div>

      <div className="sui-flex sui-basis-2/12">&nbsp;</div>

      <div className="sui-text-end sui-basis-1/12">
        <Icon
          data-testid={`discount-delete-${discount}`}
          name="delete"
          style={{
            color: '#e8301c',
            cursor: 'pointer',
          }}
          onClick={() => onDelete(discount)}
        />
      </div>
    </div>
  );
};
