import * as React from 'react';
import { HouseholdPerson, Registration, RegistrationLineItem, RegistrationUpdate } from 'core/api';
import { Button } from '@teamsnap/teamsnap-ui';
import { Icon } from '@teamsnap/snap-ui';
import { getUSDFormat } from 'core/utilities';
import { useAppDispatch, useAppNavigate } from 'state/hooks';
import {
  addToWaitlist,
  loadFieldAdjustmentResults,
  loadRegistrationsWithDiscounts,
  loadFieldResults,
  updateRegistration,
  updateRegistrationStatus,
} from 'state/registration/registrationSlice';
import { ConfirmationModal } from 'components/shared/ConfirmationModal';
import { FeatureFlagService } from 'frontend-toolkit';
import { FeatureFlagConfig, FeatureFlags } from 'core/feature-flags';
import ENVIRONMENT from 'core/environment';
import { useFormSelector } from 'state/form/formSlice';
import { DiscountType } from '../Discounts';

interface MemberCartDetailsProps {
  member: HouseholdPerson;
  registrations: Registration[];
  appliedDiscounts: DiscountType[];
  registrationsAtMaxCapacity: Registration[];
  waitlistedRegistrations: Registration[];
  registrationsWithInactiveGroup: Registration[];
}

export const MemberCartDetails = ({
  member,
  registrations,
  appliedDiscounts,
  registrationsAtMaxCapacity,
  waitlistedRegistrations,
  registrationsWithInactiveGroup,
}: MemberCartDetailsProps) => {
  const [confirmationVisible, setConfirmationVisible] = React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);
  const [registration, setRegistration] = React.useState<Registration | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const form = useFormSelector();
  const { organizationId, id } = form || {};

  // NOTE: Feature flag for capacity limits needs to be removed once the feature is fully released
  const isCapacityLimitsEnabled = FeatureFlagService.isFeatureEnabledForOrg(
    FeatureFlagConfig[FeatureFlags.PARTICIPANT_GROUP_CAPACITY_LIMIT],
    ENVIRONMENT,
    organizationId
  );

  // TODO: Remove Feature Flag once feature is fully released.
  const isParticipantGroupWaitlistEnabled = FeatureFlagService.isFeatureEnabledForOrg(
    FeatureFlagConfig[FeatureFlags.PARTICIPANT_GROUP_WAITLIST],
    ENVIRONMENT,
    organizationId
  );

  const memberRegistrations = registrations.filter((reg) => reg.householdPersonId === member.id);

  const discountMap = appliedDiscounts.reduce((acc, discount) => {
    const key = `${discount.sourceId}-${discount.sourceType}-${discount.formResultId}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(discount);
    return acc;
  }, {} as { [key: string]: DiscountType[] });

  const getDiscountsForLineItem = (item: RegistrationLineItem, registration: Registration) => {
    const key = `${item.scopeId}-${item.scopeType}-${registration.id}`;
    return discountMap[key] || [];
  };

  const updateWaitlistStatus = (registration: Registration) => {
    dispatch(addToWaitlist({ registrationId: registration.id }));
  };

  const showConfirmation = (registration: Registration) => {
    setRegistration(registration);
    setConfirmationVisible(true);
  };

  const hideConfirmation = () => {
    setRegistration(null);
    setConfirmationVisible(false);
  };

  const remove = async () => {
    setSubmitting(true);
    if (registration) {
      const response: any = await dispatch(
        updateRegistrationStatus({
          registrationId: registration.id,
          body: { status: RegistrationUpdate.status.DELETED },
        })
      );

      if (!response.payload.error && id) {
        const localDiscounts = JSON.parse(localStorage.getItem(`form-${id}-discounts`) ?? '{}')?.discounts;
        await dispatch(loadRegistrationsWithDiscounts({ formId: id, couponCodes: (localDiscounts ?? []).join(',') }));
      }
    }
    setSubmitting(false);
    hideConfirmation();
  };

  const edit = async (registration: Registration) => {
    const matchedGroup = form?.participantGroups.find((g) => g.id === registration.participantGroupId);
    const currentGroup = matchedGroup
      ? {
          participantGroupId: matchedGroup.id,
          amount: matchedGroup.participantFee,
          description: matchedGroup.description,
          name: matchedGroup.name,
          waitlistAllowed: isParticipantGroupWaitlistEnabled ? matchedGroup.waitlistAllowed : false, // TODO: Remove check once feature is fully released.
        }
      : undefined;

    dispatch(
      updateRegistration({
        currentRegistration: registration,
        currentMember: member,
        currentGroup: currentGroup,
      })
    );

    await dispatch(loadFieldResults(registration.id))
      .then(() => dispatch(loadFieldAdjustmentResults(registration.id)))
      .then(() => navigate(`/form/${registration.formId}/answerQuestions`));
  };

  const getDiscountsForLineItemName = (item: RegistrationLineItem, registration: Registration) => {
    return getDiscountsForLineItem(item, registration)
      .map((discount) => `${discount.name} (${getUSDFormat(discount.amount / 100)})`)
      .join(', ');
  };

  const getDiscountsForLineItemTotal = (item: RegistrationLineItem, registration: Registration) => {
    return getDiscountsForLineItem(item, registration).reduce((accumulator, curr) => {
      return (accumulator = accumulator + Math.abs(curr.amount));
    }, 0);
  };

  const calcTotal = () => {
    let total = memberRegistrations
      .flatMap((r) => [...r.lineItems, ...r.lineItems.flatMap((item) => getDiscountsForLineItem(item, r))])
      .reduce((a, i) => a + i.amount, 0);
    total = Math.sign(total) === -1 ? 0 : total;
    return total;
  };

  const total = calcTotal();
  const hasRegistration = (reges: Registration[], reg: Registration) => reges.some((r) => r.id === reg.id);

  const isError = (registration: Registration) =>
    hasRegistration(registrationsWithInactiveGroup, registration) ||
    (isCapacityLimitsEnabled && hasRegistration(registrationsAtMaxCapacity, registration));

  const displayDetails = () => {
    return (memberRegistrations?.length ?? 0) > 0 ? (
      <>
        <ConfirmationModal
          dismissText="Cancel"
          handleClose={hideConfirmation}
          confirmationText="Continue"
          handleConfirm={remove}
          isOpen={confirmationVisible}
          text="Are you sure you want to remove this from the cart?"
          isSubmitting={isSubmitting}
          testId="confirm-remove-from-cart-modal"
        />
        <div className="sui-bg-white sui-py-2 sui-px-3 sui-mt-2 sui-shadow-up sui-rounded" data-testid="member-cart">
          <div className="sui-flex sui-align-items sui-justify-between sui-mb-2">
            <h2 className="sui-body sui-font-bold">
              {member.person.firstName} {member.person.lastName}
            </h2>
          </div>
          <hr className="sui-my-2 sui-border-accent-background-weak/50" />
          <>
            {memberRegistrations.map((registration) => (
              <div key={`${member.id}_${registration.id}`}>
                {registration.lineItems.map((item) => (
                  <div>
                    <div
                      key={`${registration.id}_${item.name}`}
                      className={
                        'sui-flex sui-justify-between sui-body sui-py-0.5 sui-text-gray-7 sui-font-bold ' +
                        (!item.isRegistrationFee && item.name === 'Waitlist' ? ' sui-text-red' : '') +
                        (isError(registration) ? ' u-colorNegative' : '')
                      }
                      data-testid="member-cart-item"
                    >
                      <div className="sui-flex-1" data-testid="addon-fee-title">
                        <div>
                          <div>
                            {item.quantity > 1 ? (
                              <span>
                                {item.name} - {item.quantity} @ {getUSDFormat(item.basePrice / 100)}
                              </span>
                            ) : (
                              item.name
                            )}
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="sui-flex sui-items-center">
                          <div className="sui-text-right sui-ml-2" data-testid="addon-fee-amount">
                            {getUSDFormat((item.amount - getDiscountsForLineItemTotal(item, registration)) / 100)}
                          </div>
                        </div>

                        <div className="sui-text-end">
                          {waitlistedRegistrations?.find((reg) => reg.id === registration.id) &&
                            item.isRegistrationFee && (
                              <Button
                                mods="sui-h-auto sui-label sui-min-w-auto member-cart-item-join-waitlist"
                                type="link"
                                onClick={() => updateWaitlistStatus(registration)}
                              >
                                Join Waitlist
                              </Button>
                            )}
                        </div>
                      </div>
                    </div>

                    {getDiscountsForLineItem(item, registration).length > 0 && (
                      <div
                        key={`${registration.id}_${item.name}_discount`}
                        className="sui-flex sui-justify-between sui-body sui-py-0.5 sui-text-gray-6 sui-font-semibold"
                        data-testid="discount-member-cart-item"
                      >
                        <div className="sui-flex-1" data-testid="discounts-fee-title">
                          {getDiscountsForLineItemName(item, registration)}
                        </div>

                        <div className="sui-flex sui-items-center">
                          <div className="sui-text-right sui-ml-2 sui-line-through" data-testid="discounts-fee-amount">
                            {getUSDFormat(item.amount / 100)}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}

                <div className="sui-flex sui-justify-end sui-ml-2 sui-mt-2">
                  <>
                    <Icon
                      data-testid="member-cart-item--edit"
                      name="edit"
                      style={{ cursor: 'pointer', color: '#1364DE' }}
                      onClick={() => edit(registration)}
                    />

                    <Icon
                      data-testid="member-cart-item--remove"
                      name="delete"
                      style={{
                        marginLeft: '8px',
                        color: '#e8301c',
                        cursor: 'pointer',
                      }}
                      onClick={() => showConfirmation(registration)}
                    />
                  </>
                </div>
                <hr className="sui-my-2 sui-border-accent-background-weak/50" />
              </div>
            ))}
          </>
          <div className="sui-flex sui-justify-between sui-body sui-font-bold">
            <span>Participant Total</span>
            <span data-testid="participant-total">{getUSDFormat(total / 100)}</span>
          </div>
        </div>
      </>
    ) : null;
  };

  return displayDetails();
};
